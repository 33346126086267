import {
  Recommendation,
  PrettyColumn,
  AllStateProgresses,
  StateProgress,
  RecommendationSearchResponse,
  LifecycleSearchResponse,
  RecommendationLifecycle,
  LifecycleDetails,
  LifecycleActionOption,
  ClinicStatFilterNamedDataWithId,
  Patient,
  LifecycleActionMetaData,
  AllLifecycleFlows,
  LifecyclePath,
  Procedure,
} from '@/types/Api';

import {
  performPostToBackend,
  performPutToBackend,
  performGetToBackend,
  performDeleteToBackend,
  performPatchToBackend,
} from '@/flagler-api/backend-api';

import { PatientDetailsType } from '@/types/Ui';

import { dateStringToServerDateString } from '@/utils/date';

type SortKey = {
  target: string;
  name: string;
};
const columnKey2SortKey: Record<string, SortKey> = {
  date: { name: 'nextApptDate', target: 'recommendation' },
  // @TODO progress sorts by # of steps, not % complete; would need BE support
  progress: { name: 'step', target: 'recommendationLifecycle' },
  treatment: { name: 'procedureName', target: 'recommendationLifecycle' },
  status: { name: 'prettyState', target: 'recommendationLifecycle' },
  updatedAt: { name: 'updatedAt', target: 'recommendationLifecycle' },
};

function sortKeyForColumnKey(key: string) {
  return (
    columnKey2SortKey[key] ?? { name: key, target: 'recommendationLifecycle' }
  );
}

type RecommendationApiPostBody = {
  includeClinic?: boolean;
  includePatient?: boolean;
  includeRecommendationInfo?: boolean;
  includeRecommendationLifecycle?: boolean;
  limit: number;
  page: number;
  query: {
    clinic: {
      clinicIds: string[];
    };
    clinicAttributes?: {
      searchTokens: string[];
    };
    patient?: {
      searchToken: string;
    };
    recommendedProcedures?: [
      {
        name: string;
      }
    ];
    startDate?: string;
    endDate?: string;
    nextApptStartDate?: string;
    nextApptEndDate?: string;
    status?: string[]; // recommendation.status and recommendationLifecycle.stateProgress (expected to be the same)
    recommendationLifecycle?: {
      // returns the recommendations only if the associated active recommendationLifecycle matches the below
      procedureNames?: string[];
      states?: string[];
      providerNames?: string[];
      recommendationLifecyclePaths?: LifecyclePath[];
    };
    _id?: string;
  };
  sort?: {
    name: string;
    target?: string;
    isPatientAttributesKey?: boolean;
    order: 1 | -1;
  }[];
};

export const getPagedRecommendations = async (
  clinicId: string,
  selectedDate: string | undefined,
  locationFilter: string[] | undefined,
  sortColumnKey: string | undefined,
  sortDirection: number | undefined,
  piiCols: PrettyColumn[],
  searchText = '',
  procedureName: null | undefined | string = undefined,
  providerName: null | undefined | string = undefined,
  pageNumber = 1,
  pageSize = 10
): Promise<RecommendationSearchResponse> => {
  console.warn('engagement.ts > getPagedRecommendations is deprecated');
  const usePageSize = pageSize || 10;

  const postBody: RecommendationApiPostBody = {
    query: {
      clinic: {
        clinicIds: [clinicId],
      },
      patient:
        searchText.length < 2
          ? undefined
          : {
              searchToken: searchText, // <-- searches within attributesKey.v and attributes.v
            },
      recommendedProcedures: procedureName
        ? [
            {
              name: procedureName,
            },
          ]
        : undefined,
      status: ['Pending Action'],
      startDate: selectedDate,
      endDate: selectedDate,
      recommendationLifecycle: providerName
        ? {
            providerNames: [providerName],
          }
        : undefined,
    },
    includePatient: true,
    includeClinic: false,
    includeRecommendationInfo: false,
    includeRecommendationLifecycle: false, // this can be toggled on/off at BE for POST /recommendation/search
    page: pageNumber,
    limit: usePageSize,
  };
  if (selectedDate) {
    postBody.query.startDate = selectedDate;
    postBody.query.endDate = selectedDate;
  }
  if (locationFilter && locationFilter.length > 0) {
    postBody.query.clinicAttributes = { searchTokens: locationFilter };
  }
  // if (sortedColumn && sortedColumn !== '') {
  //   const name = getRawColumnFromPrettyColumn(piiCols, sortedColumn);
  //   if (name) {
  //     postBody.sort = [{
  //       name,
  //       target: 'patient',
  //       order: sortDirection === 'UP' ? 1 : -1
  //     }]
  //   }
  // }
  if (sortColumnKey !== undefined) {
    const k = sortKeyForColumnKey(sortColumnKey);
    postBody.sort = [
      {
        name: k.name,
        order: sortDirection === 1 ? 1 : -1,
        target: k.target,
      },
    ];
  } else {
    postBody.sort = [
      { name: 'dates', order: -1, target: 'recommendation' },
      { name: 'nextApptDate', order: 1, target: 'recommendation' },
    ];
  }
  const data = await performPostToBackend(
    '/recommendation/lifecycle/search',
    postBody
  );
  return data.data;
};

export const updateTreatment = async (
  clinicId: string,
  recommendationId: string,
  procedureName: string,
  actionMetaData?: LifecycleActionMetaData
): Promise<RecommendationLifecycle> => {
  const postBody = {
    clinicIds: [clinicId],
    recommendationIds: [recommendationId],
    data: {
      recommendedProcedureName: procedureName,
      actionMetaDataResult: actionMetaData,
    },
  };
  // In case in the future we want to restore passing an action with treatment
  // if (actionState && action) {
  //   postBody.data.makeAction = {
  //     state: actionState,
  //     name: action
  //   }
  // }
  const data = await performPostToBackend(
    '/recommendation/lifeCycle/updateTreatment',
    postBody
  );
  return data.data;
};

type GetPagedLifecycleRecommendationsOptions = {
  clinicId: string;
  selectedDate?: string | undefined;
  nextApptStartDate?: string;
  nextApptEndDate?: string;
  locationFilter?: string[] | undefined;
  sortColumnKey?: string | undefined;
  sortDirection?: number | undefined;
  stateProgress?: StateProgress | StateProgress[];
  searchText?: string;
  procedureName?: null | string;
  recommendedProcedureName?: null | string | string[];
  lifecyclePath?: null | LifecyclePath | LifecyclePath[];
  providerName?: null | string;
  state?: null | string;
  recommendationId?: string | undefined;
  pageNumber?: number;
  pageSize: number;
};
export const getPagedLifecycleRecommendations = async ({
  clinicId,
  selectedDate,
  nextApptStartDate,
  nextApptEndDate,
  locationFilter,
  sortColumnKey,
  sortDirection,
  stateProgress = 'In Progress',
  searchText,
  procedureName = undefined,
  lifecyclePath,
  recommendedProcedureName,
  providerName = undefined,
  state = undefined,
  recommendationId,
  pageNumber = 1,
  pageSize = 10,
}: GetPagedLifecycleRecommendationsOptions): Promise<LifecycleSearchResponse> => {
  const usePageSize = pageSize || 10;
  const recommendedProcedures = Array.isArray(recommendedProcedureName)
    ? recommendedProcedureName.map((name) => ({ name }))
    : recommendedProcedureName
    ? [
        {
          name: recommendedProcedureName,
        },
      ]
    : undefined;

  const postBody: RecommendationApiPostBody = {
    query: {
      clinic: {
        clinicIds: [clinicId],
      },
      patient:
        searchText && searchText.length >= 2
          ? {
              searchToken: searchText,
            }
          : undefined,
      status: Array.isArray(stateProgress) ? stateProgress : [stateProgress],
      recommendedProcedures,
      recommendationLifecycle:
        procedureName || providerName || state || lifecyclePath
          ? {
              procedureNames: procedureName ? [procedureName] : undefined,
              providerNames: providerName ? [providerName] : undefined,
              states: state ? [state] : undefined,
              recommendationLifecyclePaths:
                typeof lifecyclePath === 'string'
                  ? [lifecyclePath]
                  : lifecyclePath || undefined,
            }
          : undefined,

      startDate: selectedDate,
      endDate: selectedDate,
      nextApptStartDate,
      nextApptEndDate,
      _id: recommendationId,
    },
    includePatient: true,
    includeClinic: false,
    includeRecommendationInfo: false,
    includeRecommendationLifecycle: true, // this is always true at BE for POST /recommendation/lifecycle/search
    page: pageNumber,
    limit: usePageSize,
  };
  if (selectedDate) {
    postBody.query.startDate = selectedDate;
    postBody.query.endDate = selectedDate;
  }
  if (locationFilter && locationFilter.length > 0) {
    postBody.query.clinicAttributes = { searchTokens: locationFilter };
  }
  // if (sortedColumn && sortedColumn !== '') {
  //   const name = getRawColumnFromPrettyColumn( , sortedColumn);
  //   if (name) {
  //     postBody.sort = [{
  //       name,
  //       target: 'patient',
  //       order: sortDirection === 'UP' ? 1 : -1
  //     }]
  //   }
  // }
  if (sortColumnKey !== undefined) {
    const k = sortKeyForColumnKey(sortColumnKey);
    postBody.sort = [
      {
        name: k.name,
        order: sortDirection === 1 ? 1 : -1,
        target: k.target,
      },
    ];
  } else {
    postBody.sort = [
      { name: 'dates', order: -1, target: 'recommendation' },
      { name: 'nextApptDate', order: 1, target: 'recommendation' },
      { name: 'step', order: -1, target: 'recommendationLifecycle' },
    ];
  }

  try {
    const data = await performPostToBackend(
      '/recommendation/lifecycle/search',
      postBody
    );
    return data.data;
  } catch (e) {
    console.warn('getPagedLifecycleRecommendations failed');
    console.error(e);
    return {
      page: 0,
      limit: 0,
      pageTotal: 0,
      totalCount: 0,
      hasPevPage: false,
      hasNextPage: false,
      data: [],
    };
  }
};

export const getLifecycleRecommendationById = async (
  clinicId: string,
  id: string
): Promise<Recommendation | undefined> => {
  const postBody: RecommendationApiPostBody = {
    query: {
      clinic: {
        clinicIds: [clinicId],
      },
      recommendationLifecycle: {
        recommendationLifecycleIds: [id],
      },
      status: [
        'Pending Action',
        'In Progress',
        'Scheduled for Treatment',
        'All Patient - Disqualified',
        'All Patient - Completed',
      ],
    },
    includePatient: true,
    includeClinic: false,
    includeRecommendationInfo: false,
    includeRecommendationLifecycle: true, // this is always true at BE for POST /recommendation/lifecycle/search
    page: 1,
    limit: 1,
  };
  try {
    const data = await performPostToBackend(
      '/recommendation/lifecycle/search',
      postBody
    );
    return data.data.data[0];
  } catch (e) {
    console.warn('getLifecycleRecommendationById failed');
    console.error(e);
    return undefined;
  }
};

type PatientSortOption = {
  ['patientFormStats.stats']: number;
};
type GetPatientsOptions = {
  clinicId: string;
  searchText?: string | undefined;
  patientIds?: string[] | string | undefined;
  stateProgress?: false | StateProgress[] | undefined;
  risk?: number | undefined;
  severity?: number | undefined;
  daysSinceLastVisit?: number[] | undefined;
  page?: number;
  limit?: number;
  includeActiveLifecycle?: boolean;
  includePatientFormStats?: boolean;
  hasPatientForms?: boolean;
  sort?: PatientSortOption[];
};

export async function getPatients({
  clinicId,
  searchText,
  patientIds,
  stateProgress,
  risk,
  severity,
  daysSinceLastVisit,
  page = 1,
  limit = 10,
  includeActiveLifecycle = true,
  includePatientFormStats = false,
  hasPatientForms = undefined,
  sort = undefined,
}: GetPatientsOptions): Promise<{
  data: Patient[];
  pageTotal: number;
  totalCount: number;
}> {
  const careAlignment =
    risk !== undefined &&
    severity !== undefined &&
    Array.isArray(daysSinceLastVisit) &&
    daysSinceLastVisit.length == 2
      ? {
          riskBucket: risk,
          severity,
          daysSinceLastVisit: {
            boundaries: daysSinceLastVisit,
          },
        }
      : undefined;
  const postBody = {
    query: {
      clinic: { clinicIds: [clinicId] },
      searchToken: searchText,
      _id: !Array.isArray(patientIds) ? patientIds : undefined,
      patientIds: Array.isArray(patientIds) ? patientIds : undefined,
      status: stateProgress === false ? AllStateProgresses : stateProgress,
      careAlignment,
      hasPatientForms,
    },
    sort,
    format: 'object',
    includeActiveLifecycle,
    includePatientFormStats,
    page,
    limit,
  };
  const data = await performPostToBackend('/patient/search', postBody);
  return {
    ...data.data,
    // massage into same format as is attached to recommendations
    data: data.data.data.map((p: any) => ({
      ...p.attributesKey,
      ...p.attributes,
      recommendationLifecycle: p.recommendationLifecycle,
      _id: p._id,
      attributesKey: p.attributesKey,
      attributes: p.attributes,
      patientMeta: {
        careAlignment: p.careAlignment,
        activeChat: p.activeChat,
      },
      stats: p.stats,
    })),
  };
}

type GetPatientDetailsBodyType = {
  clinic: { clinicIds: string[] };
  patient: { patientIds: string[] };
  includeTimeline: boolean;
  includeNotes: boolean;
  includeNotesFileContents: boolean;
  notesType?:
    | 'Base64 Encoded PDF'
    | 'Base64 Encoded HTML'
    | 'Base64 Encoded Plain Text';
};

export const getPatientDetails = async (
  clinicId: string,
  patientId: string,
  includeTimeline: boolean = true,
  includeNotes: boolean = false,
  includeNotesFileContents: boolean = false
): Promise<PatientDetailsType> => {
  const postData: GetPatientDetailsBodyType = {
    clinic: { clinicIds: [clinicId] },
    patient: { patientIds: [patientId] },
    includeTimeline,
    includeNotes,
    includeNotesFileContents,
  };
  const data = await performPostToBackend('/patient/details', postData);

  if (data && data.data) {
    return {
      timeline: data.data.timeline?.data ?? [],
      attachments: data.data.notes?.data ?? [],
    };
  } else {
    return {
      timeline: [],
      attachments: [],
    };
  }
};

export const updateAction = async (
  clinicId: string,
  patientId: string,
  recommendationId: string,
  state: string,
  name: string, // action
  metaData?: LifecycleActionMetaData
): Promise<RecommendationLifecycle> => {
  const postBody = {
    clinicIds: [clinicId],
    patientIds: [patientId],
    recommendationIds: [recommendationId],
    data: {
      makeAction: {
        state,
        name,
      },
      actionMetaDataResult: metaData,
    },
  };
  const data = await performPostToBackend(
    '/recommendation/lifeCycle/updateState',
    postBody
  );
  return data.data as RecommendationLifecycle;
};

export type ExportRecommendationLifecycleOptions = {
  clinicId: string;
  recommendationLifecycleId: string;
  provider?: ClinicStatFilterNamedDataWithId;
  changeHistory?: { changeHistoryId: string; changedAt: string };
  procedure?: Partial<Procedure>; // vendors or tags
  checklist?: any;
};
export const updateRecommendationLifecycle = async ({
  // clinicId: string,
  // recommendationLifecycleId: string,
  // provider?: ClinicStatFilterNamedDataWithId,
  // changeHistory?: { changeHistoryId: string; changedAt: string },
  // procedure?: Partial<Procedure> // vendors or tags
  // checklist?: any
  clinicId,
  recommendationLifecycleId,
  provider = undefined,
  changeHistory = undefined,
  procedure = undefined,
  checklist = undefined,
}: ExportRecommendationLifecycleOptions) => {
  const putBody = {
    clinicIds: [clinicId],
    data: {
      provider,
      changeHistory, // update specific history item for this recLifecycleId
      procedure,
      checklist: checklist ? { result: checklist } : undefined,
    },
  };
  const data = await performPutToBackend(
    '/recommendation/lifecycle/' + recommendationLifecycleId,
    putBody
  );
  return data.data;
};

export const updateRecommendationComment = async (
  clinicId: string,
  recommendationId: string,
  comment: string
) => {
  const putBody = {
    clinicIds: [clinicId],
    comments: {
      text: comment,
    },
  };
  return await performPutToBackend(
    '/recommendation/' + recommendationId,
    putBody
  );
};

export const updateRecommendationDate = async (
  clinicId: string,
  recommendationId: string,
  date: string | null
) => {
  const nextApptDate =
    date === null ? null : dateStringToServerDateString(date);
  if (nextApptDate !== undefined) {
    const putBody = {
      clinicIds: [clinicId],
      nextApptDate,
    };
    return await performPutToBackend(
      '/recommendation/' + recommendationId,
      putBody
    );
  }
};

export const updatePatientPhone = async (
  clinicId: string,
  patientId: string,
  phone: string
) => {
  const postBody = {
    clinicIds: [clinicId],
    checkUnique: false,
    data: {
      _id: patientId,
      clinic: clinicId,
      attributes: {
        phone,
      },
    },
  };
  return performPostToBackend('/patient/update', postBody);
};

export const getRecommendationDetails = async (
  clinicId: string,
  patientId: string,
  procedureId: string // from lifecycle
): Promise<LifecycleDetails> => {
  const postBody = {
    clinicIds: [clinicId],
    patientIds: [patientId],
    recommendedProcedureIds: [procedureId],
  };
  const data = await performPostToBackend(
    '/recommendation/lifecycle/details',
    postBody
  );
  return data.data as LifecycleDetails;
};

export const getValidActionsForClinicId = async (
  clinicId: string
): Promise<LifecycleActionOption[]> => {
  try {
    const d = await performGetToBackend('/recommendation/lifecycle/states', {
      clinicId,
      procedureName: 'pending_selection',
      state: 'pending_selection',
    });
    return d.data.length === 1 ? d.data[0].actions ?? [] : [];
  } catch (e) {
    console.warn('getValidActionsForClinicId failed');
    console.error(e);
    return [];
  }
};

export const addPatient = async (
  clinicId: string,
  patientId: string,
  attributesKey: Record<string, string>,
  attributes: Record<string, string>
): Promise<Recommendation> => {
  // waiting on new API endpoint rather than teasing this out
  const postBody = {
    clinicIds: [clinicId],
    checkUnique: true,
    data: [
      {
        clinic: clinicId,
        attributesKey: attributesKey,
        attributes: attributes,
        _id: patientId,
      },
    ],
  };

  const data = await performPostToBackend(
    '/patient/addPatientForLifecycle',
    postBody
  );
  return data.data.recommendation;
};

export const getPatientsPendingAction = async (
  clinicId: string,
  selectedDate: string | undefined = undefined,
  locationFilter: string[] | undefined = undefined,
  sortColumnKey: string | undefined = undefined,
  sortDirection: number | undefined = undefined,
  stateProgress: StateProgress = 'In Progress',
  searchText = '',
  procedureName: null | undefined | string = undefined,
  pageNumber = 1,
  pageSize = 10
): Promise<LifecycleSearchResponse> => {
  const usePageSize = pageSize || 10;

  const postBody: RecommendationApiPostBody = {
    query: {
      clinic: {
        clinicIds: [clinicId],
      },
      patient:
        searchText.length < 2
          ? undefined
          : {
              searchToken: searchText,
            },
      status: [stateProgress],

      recommendationLifecycle: procedureName
        ? {
            procedureNames: [procedureName],
          }
        : undefined,

      startDate: selectedDate,
      endDate: selectedDate,
    },
    includePatient: true,
    includeClinic: false,
    includeRecommendationInfo: false,
    includeRecommendationLifecycle: true,
    page: pageNumber,
    limit: usePageSize,
  };
  if (selectedDate) {
    postBody.query.startDate = selectedDate;
    postBody.query.endDate = selectedDate;
  }
  if (locationFilter && locationFilter.length > 0) {
    postBody.query.clinicAttributes = { searchTokens: locationFilter };
  }
  // if (sortedColumn && sortedColumn !== '') {
  //   const name = getRawColumnFromPrettyColumn(piiCols, sortedColumn);
  //   if (name) {
  //     postBody.sort = [{
  //       name,
  //       target: 'patient',
  //       order: sortDirection === 'UP' ? 1 : -1
  //     }]
  //   }
  // }
  if (sortColumnKey !== undefined) {
    const k = sortKeyForColumnKey(sortColumnKey);
    postBody.sort = [
      {
        name: k.name,
        order: sortDirection === 1 ? 1 : -1,
        target: k.target,
      },
    ];
  } else {
    postBody.sort = [
      { name: 'dates', order: -1, target: 'recommendation' },
      { name: 'nextApptDate', order: 1, target: 'recommendation' },
      { name: 'step', order: -1, target: 'recommendationLifecycle' },
    ];
  }
  const data = await performPostToBackend(
    '/recommendation/lifecycle/daysSinceAction',
    postBody
  );
  return data.data;
};

export const getProviderCompletionRate = async (
  clinicId: string,
  sortColumnKey: string | undefined = 'completedPerc',
  sortDirection: number | undefined = -1,
  pageNumber = 1,
  pageSize = 10
) => {
  const postBody = {
    query: {
      clinic: {
        clinicIds: [clinicId],
      },
    },
    include: 'provider',
    page: pageNumber,
    limit: pageSize,
    sort: sortColumnKey
      ? [
          {
            target: 'recommendationCompletionRate',
            order: sortDirection ?? -1,
            name: sortColumnKey,
          },
          // TODO please add secondary sort on name: 'total' descending (or other secondary sort as team sees fit)
        ]
      : undefined,
  };
  const data = await performPostToBackend(
    '/recommendation/lifecycle/completionRate',
    postBody
  );
  return data.data;
};

export const getAllLifecycles = async (
  clinicId: string
): Promise<AllLifecycleFlows> => {
  const d = await performGetToBackend('/recommendation/lifecycle/states', {
    clinicId,
    lifecyclePathName: '*', // 'LIFECYCLE_WITH_PSYCH_EVAL',
  });

  return d.data || {};
};

export const getAllLifecyclesForAllClinics = async (): Promise<
  Record<string, AllLifecycleFlows>
> => {
  const d = await performGetToBackend('/recommendation/lifecycle/states/all');

  return d.data || {};
};

export const deleteCommentByIndex = async (
  recId: string,
  commentIndex: number
) => {
  const d = await performDeleteToBackend(
    '/recommendation/' + recId + '/comment/' + commentIndex
    // { comments: { text: 'hello, world' }}
  );
  return d.data || [];
};

export const updateCommentByIndex = async (
  recId: string,
  commentIndex: number,
  text: string
) => {
  const d = await performPatchToBackend(
    '/recommendation/' + recId + '/comment/' + commentIndex,
    { comments: { text } }
  );
  return d.data || [];
};
