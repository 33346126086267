import {
  performPostToBackend,
  performGetToBackend,
  performPutToBackend,
  performDeleteToBackend,
  PagedResponse,
} from '@/flagler-api/backend-api';
import { Company, CompanyFeatureFlags } from '@/types/Api';
import { CompanyWithClinics } from '@/types/Ui';

type GetCompaniesResponse = PagedResponse & { data: Company[] };

export async function getCompanies(
  page = 1,
  limit = 10,
  search?: string
): Promise<GetCompaniesResponse | undefined> {
  const response = await performPostToBackend('/company/search', {
    query: { name: search },
    page, // page #, 1-based
    limit, // page size
  });
  return response?.data;
}

export async function getUserCompanies(): Promise<
  CompanyWithClinics[] | undefined
> {
  const response = await performGetToBackend('/user/filters');
  if (response?.data?.lvl1?.company?.length > 0) {
    return response.data.lvl1.company.map((c: Company) => {
      return {
        ...c,
        clinics: response.data.lvl2[c._id],
      };
    });
  } else {
    return undefined;
  }
}

export async function getFeatureFlagsForCompanyId(
  companyId: string
): Promise<CompanyFeatureFlags | undefined> {
  const response = await performGetToBackend('/company/featureflag/', {
    companyId,
  });
  return response?.data;
}

export async function updateCompanyFeatureFlags(
  companyId: string,
  flags: CompanyFeatureFlags
): Promise<CompanyFeatureFlags | undefined> {
  const response = await performPutToBackend(
    `/company/featureflag/${companyId}`,
    flags
  );
  return response.data;
}

export async function updateCompany(
  c: Partial<Company>,
  autoUpsert = false
): Promise<Company | undefined> {
  console.assert(c._id !== undefined);
  const response = await performPutToBackend(`/company/${c._id}`, c);
  const updatedCompany = response.data;

  return updatedCompany;
}

export async function createCompany(c: Company): Promise<Company | undefined> {
  const response = await performPostToBackend('/company', c);
  const newCompany = response.data;

  return newCompany;
}

export async function deleteCompany(companyId: string) {
  return await performDeleteToBackend(`/company/${companyId}`);
}
