import * as Sentry from '@sentry/vue';
import { defineStore } from 'pinia';

import { Role, Permission } from '@/types/Api';
import { UserData } from '@/types/Ui';

import { getAllRolesAndPermissions } from '@/flagler-api/users';

export const useUserStore = defineStore('UserStore', () => {
  // all available roles
  const roles = ref<Role[] | undefined>();
  // all available permissions
  const permissions = ref<Permission[] | undefined>();
  // logged-in user data
  const userData = ref<UserData | undefined>();

  const roleOptions = computed(
    (): { title: string; value: string; privileged: boolean }[] => {
      if (roles.value !== undefined) {
        return roles.value
          .map((r) => ({
            title: r.prettyName,
            value: r.name,
            privileged: !!r.privileged,
          }))
          .sort((a, b) => a.title.localeCompare(b.title));
      } else {
        return [];
      }
    }
  );

  const permissionOptions = computed(
    (): { title: string; value: Permission }[] => {
      if (permissions.value !== undefined) {
        return permissions.value.map((p) => {
          return { title: `${p.action} ${p.subject}`, value: p };
        });
      } else {
        return [];
      }
    }
  );

  watch(userData, (u: UserData | undefined) => {
    if (u) {
      localStorage.setItem('userData', JSON.stringify(u));
      Sentry.setUser({
        email: u.email,
        id: u._id,
        username: u.fullName,
      });
    } else {
      localStorage.removeItem('userData');
      Sentry.setUser(null);
    }
  });

  const keyedRoles = computed((): { [key: string]: Role } => {
    const result: { [key: string]: Role } = {};
    if (userData.value && userData.value.roles && roles.value) {
      for (const roleName of userData.value.roles) {
        const role = roles.value?.find((r) => r.name === roleName);
        if (role) {
          result[roleName] = role;
        }
      }
    }
    return result;
  });

  // startup() assumes you have a valid token
  const startup = () => {
    getAllRolesAndPermissions()
      .then(
        (data: undefined | { roles: Role[]; permissions: Permission[] }) => {
          roles.value = data?.roles;
          permissions.value = data?.permissions;
        }
      )
      .catch((e) => {
        console.warn('Error getting roles and permissions', e);
      });
    const userDataString = localStorage.getItem('userData');
    if (userDataString) {
      userData.value = JSON.parse(userDataString);
    }
  };

  // does logged in user have a given role
  const hasRole = (roleName: string) => {
    return keyedRoles.value[roleName] !== undefined;
  };

  return {
    roles,
    roleOptions,
    permissions,
    permissionOptions,
    userData,
    keyedRoles,
    hasRole,
    startup,
  };
});
