import Pusher from 'pusher-js';
import { defineStore } from 'pinia';
import { randomUuid } from '@/utils/randomUuid';

function createSecureRandomString(): string {
  return randomUuid();
}

export const usePusherStore = defineStore('PusherStore', () => {
  const pusher: Pusher = new Pusher(import.meta.env.VITE_PUSHER_APP_KEY, {
    cluster: 'us2',
  });

  const companyChannel = ref<ReturnType<typeof pusher.subscribe> | null>(null);
  const clinicChannels = ref<ReturnType<typeof pusher.subscribe>[]>([]);
  const clinicChannelsById = ref<
    Record<string, ReturnType<typeof pusher.subscribe>>
  >({});

  const instanceId = ref(randomUuid());
  let companyId: string | undefined;
  let clinicIds: string[] = [];
  let userId: string | undefined;
  const startup = (
    usingUserId: string,
    usingClinicIds: string[],
    usingCompanyId: string
  ) => {
    companyId = usingCompanyId;
    clinicIds = usingClinicIds;
    userId = usingUserId;
    companyChannel.value = pusher.subscribe(`COMPANY_${companyId}`);
    for (const id of clinicIds) {
      const channel = pusher.subscribe(`CLINIC_${id}`);
      clinicChannels.value.push(channel);
      clinicChannelsById.value[id] = channel;
    }
    // these channels are only used as a heartbeat; when they are vacated,
    // user is no longer connected
    pusher.subscribe(`USER_${userId}`);
    pusher.subscribe(`INSTANCE_${instanceId.value}`);
  };

  const shutdown = () => {
    pusher.connection.unbind('state_change');
    if (companyChannel.value) {
      companyChannel.value.unbind(); // unbind all
      companyChannel.value = null;
    }
    for (const clinicChannel of clinicChannels.value) {
      clinicChannel.unbind(); // unbind all
    }
    clinicChannels.value = [];
    clinicChannelsById.value = {};
    pusher.unsubscribe(`COMPANY_${companyId}`);
    for (const id of clinicIds) {
      pusher.unsubscribe(`CLINIC_${id}`);
    }
    pusher.unsubscribe(`INSTANCE_${instanceId.value}`);
  };

  return {
    // actions
    startup,
    shutdown,

    // getters
    companyChannel,
    clinicChannels,
    clinicChannelsById,
    instanceId,
  };
});
