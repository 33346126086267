const isClient = typeof window != 'undefined';

export function useWebLock() {
  const supported = isClient && navigator.locks;
  // "This feature is available only in secure contexts (HTTPS), in some or all
  // supporting browsers." according to the docs
  // https://developer.mozilla.org/en-US/docs/Web/API/Web_Locks_API but Chrome,
  // Safari, and FireFox all supported it in local dev in my test.
  let lockFunction = (lockName: string, f: () => Promise<void>) => {
    return f();
  };
  if (supported) {
    lockFunction = (lockName: string, f: () => Promise<void>) => {
      return navigator.locks.request(lockName, f);
    };
  }
  return {
    lockFunction,
  };
}
