const NO_AUTH_REQUIRED_PAGES = [
  'login',
  'logout',
  'forgot-password',
  'reset-password',
  'loginSuccess',
  'form',
];

/**
 * Returns true if path does not require authentication, like the auth flow
 * pages. If no path provided, uses `window.location.href`
 * @param {string | undefined} path the path to check
 */
export function onAnyUnauthenticatedPage(path: string | undefined) {
  const s = path || window.location.href;
  for (const p of NO_AUTH_REQUIRED_PAGES) {
    if (s.includes(p)) {
      return true;
    }
  }
  return false;
}
