const theme = {
  defaultTheme: 'light',
  themes: {
    light: {
      dark: false,
      colors: {
        primary: '#CD30A4',
        'on-primary': '#fff',
        secondary: '#A8AAAE',
        'on-secondary': '#fff',
        success: '#28C76F',
        'on-success': '#fff',
        info: '#00CFE8',
        'on-info': '#fff',
        warning: '#FF9F43',
        'on-warning': '#fff',
        error: '#EA5455',
        background: '#F8F7FA',
        'on-background': '#4B465C',
        'on-surface': '#4B465C',
        'grey-50': '#FAFAFA',
        'grey-100': '#F5F5F5',
        'grey-200': '#EEEEEE',
        'grey-300': '#E0E0E0',
        'grey-400': '#BDBDBD',
        'grey-500': '#9E9E9E',
        'grey-600': '#757575',
        'grey-700': '#616161',
        'grey-800': '#424242',
        'grey-900': '#212121',
        'perfect-scrollbar-thumb': '#DBDADE',
      },
      variables: {
        'border-color': '#4B465C',
        'medium-emphasis-opacity': 0.68,
        // Shadows
        'shadow-key-umbra-opacity': 'rgba(var(--v-theme-on-surface), 0.08)',
        'shadow-key-penumbra-opacity': 'rgba(var(--v-theme-on-surface), 0.12)',
        'shadow-key-ambient-opacity': 'rgba(var(--v-theme-on-surface), 0.04)',

        // the following are defaults; we are including them so we don't need
        // to rely on actual instatiated vuetify theme object every time
        'activated-opacity': 0.12,
        'border-color': '#4B465C',
        'border-opacity': 0.12,
        'disabled-opacity': 0.38,
        'dragged-opacity': 0.08,
        'focus-opacity': 0.12,
        'high-emphasis-opacity': 0.87,
        'hover-opacity': 0.04,
        'idle-opacity': 0.04,
        'pressed-opacity': 0.12,
        'selected-opacity': 0.08,
        'shadow-key-ambient-opacity': 'rgba(var(--v-theme-on-surface), 0.04)',
        'shadow-key-penumbra-opacity': 'rgba(var(--v-theme-on-surface), 0.12)',
        'shadow-key-umbra-opacity': 'rgba(var(--v-theme-on-surface), 0.08)',
        'theme-code': '#F5F5F5',
        'theme-kbd': '#212529',
        'theme-on-code': '#000000',
        'theme-on-kbd': '#FFFFFF',
      },
    },
    dark: {
      dark: true,
      colors: {
        primary: '#CD30A4',
        'on-primary': '#fff',
        secondary: '#A8AAAE',
        'on-secondary': '#fff',
        success: '#28C76F',
        'on-success': '#fff',
        info: '#00CFE8',
        'on-info': '#fff',
        warning: '#FF9F43',
        'on-warning': '#fff',
        error: '#EA5455',
        background: '#25293C',
        'on-background': '#CFD3EC',
        surface: '#2F3349',
        'on-surface': '#CFD3EC',
        'grey-50': '#26293A',
        'grey-100': '#2F3349',
        'grey-200': '#26293A',
        'grey-300': '#4A5072',
        'grey-400': '#5E6692',
        'grey-500': '#7983BB',
        'grey-600': '#AAB3DE',
        'grey-700': '#B6BEE3',
        'grey-800': '#CFD3EC',
        'grey-900': '#E7E9F6',
        'perfect-scrollbar-thumb': '#4A5072',
      },
      variables: {
        'border-color': '#CFD3EC',
        'medium-emphasis-opacity': 0.68,
        'high-emphasis-opacity': 0.68,

        // Shadows
        'shadow-key-umbra-opacity': 'rgba(15, 20, 34, 0.08)',
        'shadow-key-penumbra-opacity': 'rgba(15, 20, 34, 0.12)',
        'shadow-key-ambient-opacity': 'rgba(15, 20, 34, 0.04)',
      },
    },
  },
};

export default theme;
