import qs from 'querystring';
import {
  performGetToBackend,
  performPostToBackend,
  performPutToBackend,
} from './backend-api';
import { useRtmStore } from '@/stores/RtmStore';
import {
  Chat,
  RtmChatGroup,
  ContactClinicPriority,
  ChatStats,
} from '@/types/Api';

const getChat = async (chatId: string | undefined) => {
  const store = useRtmStore();

  if (!chatId) return null;
  const response = await performGetToBackend(`/chat/${chatId}`);
  const chat: Chat = response.data;
  store.upsertChats([chat]);
  return chat;
};

const queryChats = async (query: any) => {
  const store = useRtmStore();
  const queryString = qs.stringify(query);
  const response = await performGetToBackend(`/chat/list/all?${queryString}`);
  const { chats, totalCount, page } = response.data ?? [];
  store.upsertChats(chats);
  return { chats, totalCount, page };
};

const getChatStats = async (query: any): Promise<ChatStats> => {
  const queryString = qs.stringify(query);
  const response = await performGetToBackend(`/chat/stats?${queryString}`);
  return response?.data || null; // this handles if the response is undefined on 403 errors
};

const getFirstChat = async () => {
  const store = useRtmStore();

  const response = await performGetToBackend(`/chat/first`);
  const chat: Chat = response.data;
  store.upsertChats([chat]);
  return chat;
};

const updateChat = async (chatId: string, data: Partial<Chat>) => {
  const store = useRtmStore();

  const response = await performPutToBackend(`/chat/${chatId}`, data);

  const chat: Chat = response.data;
  store.upsertChats([chat]);
  return chat;
};

const getTwilioToken = async () => {
  const response = await performPostToBackend(`/chat/twilio/token`);
  return response.data?.token;
};

const suggestReply = async (chatId: string) => {
  const response = await performPostToBackend(`/chat/${chatId}/suggest`);
  return response.data;
};

const summarizeChat = async (chatId: string) => {
  const response = await performPostToBackend(`/chat/${chatId}/summarize`);
  return response.data;
};

const answerCCMQuestions = async (chatId: string, callIds: string[]) => {
  const response = await performPostToBackend(
    `/chat/${chatId}/answerCCMQuestions`,
    { calls: callIds }
  );
  const chat: Chat = response.data;
  const store = useRtmStore();
  store.upsertChats([chat]);
  return response.data;
};

const bulkAssign = async (data: any) => {
  const response = await performPostToBackend(`/chat/reassign`, data);
  return response.data;
};

export {
  queryChats,
  getChatStats,
  getFirstChat,
  getChat,
  updateChat,
  getTwilioToken,
  suggestReply,
  summarizeChat,
  answerCCMQuestions,
  bulkAssign,
};
