// types as returned by the API
import { JsonSchema, Layout } from '@jsonforms/core';

export type NamePrettyName = {
  name: string;
  prettyName: string;
  prettyNameShort?: string;
};

export type PrettyColumnTag = NamePrettyName;

export type PrettyColumn = {
  isPk: boolean;
  patientAttributesKey: string;
  prettyName: string;
  tags: PrettyColumnTag[];
};

// form of data returned from recommendation/search e.g.
export type ClinicBasic = {
  _id: string;
  name: string;
  prettyName: string;
};

export type ClinicBasicPlus = ClinicBasic & {
  locations: ProviderLocation[];
};

export const AllLifecyclePaths = [
  'LIFECYCLE_WITH_PSYCH_EVAL',
  'LIFECYCLE_WITH_PRIOR_AUTH',
] as const;

export type LifecyclePath = (typeof AllLifecyclePaths)[number];

export const AllProcedureFlaglerTypes = ['flagler', 'non-flagler'] as const;

export type ProcedureFlaglerType = (typeof AllProcedureFlaglerTypes)[number];

// "tag" (sub-type) or vendor
export type ProcedureMeta = {
  name: string;
  prettyName: string;
};

export type Procedure = {
  name: string;
  prettyName: string;
  prettyNameShort: string;
  type: ProcedureFlaglerType;
  description: string;
  recommendationLifecyclePath?: LifecyclePath;
  tags: ProcedureMeta[];
  vendors: ProcedureMeta[];
};

export type ClinicPiiFormat = {
  identifier_template: string;
  rows: string[];
};

// form of data returned from clinic/search e.g.
export type ClinicExtra = ClinicBasicPlus & {
  procedures: Procedure[];
  piiColumns: PrettyColumn[];
  piiFormat?: ClinicPiiFormat;
  phone?: string;
  rtmPhoneNumber?: string;
  voicemailUrl?: string;
  logoImageUrl?: string;
  faviconUrl?: string;
  brandColor?: string;
};

export type PatientMeta = {
  careAlignment: {
    risk?: Number;
    riskBucket?: Number;
    severity?: Number;
    dateOfLastVisit?: Date;
  };
  activeChat: {
    chat: string | null;
    status: string;
    updatedAt: Date;
  };
  notes?: PatientAttachment[];
};

export type PatientRaw = {
  _id: string;
  clinic: string;
  attributesKey: { k: string; v: string }[];
  attributes: { k: string; v: string }[];
  careAlignment: {
    risk?: Number;
    riskBucket?: Number;
    severity?: Number;
    dateOfLastVisit?: Date;
  };
  activeChat: {
    chat: string | null; // Chat._id
    status: string;
    updatedAt: Date;
  };
  // other attributes e.g. trackedRecLifecycle we are ignoring for now
};

export type Patient = {
  _id: string;
  // when select patient to add, we add lifecycle to UI
  recommendationLifecycle?: RecommendationLifecycle;
  // when searching for patients, we return a "flat patient" (as
  // used in recommendations) but we keep the individual parts as well for the
  // add patient/addPatientForLifecycle API
  attributes?: Record<string, string>;
  attributesKey?: Record<string, string>;
  patientMeta?: PatientMeta;
} & Record<string, string>;

export type Comment = {
  text: string;
  author: {
    user: string;
    name: string;
  };
  createdAt: string;
  updatedAt: string;
};

export type RecommendedProcedure = {
  name: string;
  prettyName: string;
  prettyNameShort: string;
  flaglerRecommended: boolean;
  _id: string;
  comments: string[]; // unconfirmed
  dates: string[];
};

export type LifecycleActionOption = {
  state: string;
  name: string;
  prettyName: string;
  nextState: string;
  description?: string;
  // ignoring actionMetaData
  nextStateProgress?: string;
  actionMetaData?: string[];
};

export type LifecycleActionTaken = {
  state: string;
  name: string;
  prettyName?: string;
  createdAt: string;
  updatedAt: string;
};

export type LifecycleActionMetaData = Record<string, string | number>;

export const AllStateProgresses = [
  'Pending Action',
  'In Progress',
  'Scheduled for Treatment',
  'All Patient - Disqualified',
  'All Patient - Completed',
] as const;

export type StateProgress = (typeof AllStateProgresses)[number];

// export type StateProgress =
//   | 'Pending Action'
//   | 'In Progress'
//   | 'Scheduled for Treatment'
//   | 'All Patient - Disqualified'
//   | 'All Patient - Completed';

export type Checklist = {
  name: string; // identifier
  prettyName: string;
  schema: JsonSchema;
  uiSchema: Layout;
  result: Object;
  updatedAt: string;
};
export type RecommendationLifecycleBase = {
  _id: string;
  state: string;
  prettyState: string;
  stateProgress: StateProgress;
  comments: string;
  actions: LifecycleActionOption[];
  recentAction: LifecycleActionTaken;
  step: number;
  lastStep: number;
  procedureName: string;
  procedure: Procedure;
};

export type LockInfo = {
  instanceId: string;
  name?: string;
  username?: string;
  avatarUrl?: string;
};

export type LockEvent = {
  clinicId: string;
  resourceType: 'recommendationLifecycle' | 'recommendation';
  resourceId: string;
  lock: boolean;
  lockInfo: LockInfo;
};

export type CommentUpdatedEvent = {
  clinicId: string;
  resourceType: 'recommendation';
  resourceId: string;
  instanceId: string;
};

export type RecommendationLifecycle = {
  _id: string;
  clinic: string; // id
  patient: string; // id
  recommendedProcedure: string; // id
  recommendation: string; // id
  state: string;
  prettyState: string;
  prettySubstate?: string;
  stateProgress: StateProgress;
  comments: string;
  actions: LifecycleActionOption[];
  recentAction: LifecycleActionTaken;
  step: number;
  lastStep: number;
  procedureName: string;
  flaglerRecommendationDate: string;
  flaglerRecommended: true; // @beepy ?
  provider: { _id: string; name: string; prettyName: string };
  procedure: Procedure;
  locked?: LockInfo;
};

// same as RecommendationLifecycle except ids are flattened
export type LifecycleActionUpdateResponse = RecommendationLifecycleBase & {
  _id: string;
  clinic: string; // id
  patient: string; // id
  recommendation: string; // id
  recommendedProcedure: string; // id
};

export type PagedResponse = {
  page: number;
  limit: number; // size of page requested (not returned)
  pageTotal: number;
  totalCount: number; // # of rows
  hasPevPage: boolean;
  hasNextPage: boolean;
};

export type LifecycleSearchResponse = PagedResponse & {
  data: Recommendation[];
};

export type PatientAttachment = {
  DocumentID: string;
  DocumentationDateTime: string; // ISO
  DocumentType: string;
  ContentType:
    | 'Base64 Encoded Plain Text'
    | 'Base64 Encoded PDF'
    | 'Base64 Encoded HTML';
  ServiceDateTime: string; // ISO
  FileContents?: string; // undefined if not loaded
};

export type Recommendation = {
  _id: string;
  comments: Comment[]; // unconfirmed
  // date: string;
  dates: string[];
  patient: Patient;
  recommendation: string;
  status: string;
  recommendationProcedureInfo: {
    _id: string;
    recommendationProcedure: string; // id
    summary: string;
  }[];
  recommendedProcedures: Partial<RecommendedProcedure>[];
  actions?: LifecycleActionOption[];
  recommendationLifecycle?: RecommendationLifecycle;
  nextApptDate?: string;
  patientMeta?: PatientMeta;
  locked?: LockInfo;
  // clinic is sometimes just an id, sometimes { _id }
};

export type RecommendationSearchResponse = PagedResponse & {
  data: Recommendation[];
};

export type LifecycleChange = {
  field: string;
  prev: string | LifecycleActionTaken;
  new: string | LifecycleActionTaken;
};

export type LifecycleDetailHistory = {
  author: { user: string; name: string };
  _id: string;
  recommendationLifecycle: string; // id
  changes: LifecycleChange[];
  changedAt: string; // datetime
};

export type LifecycleDetails = Record<string, LifecycleDetailHistory[]> & {
  patient: { _id: string };
  activeProcedureName: string;
};

// format of results from /api/v1/recommendation/lifecycle/states/lifeCyclePathName=

export type LifecycleFlowState = {
  lastStep: number;
  prettyState: string;
  state: string;
  stateProgress: StateProgress;
  step: number;
  // ingoring for now: actions
};

export type LifecycleFlow = {
  name: LifecyclePath;
  prettyName: string; // not used
  states: LifecycleFlowState[];
};

export type AllLifecycleFlows = Record<LifecyclePath, LifecycleFlow>;

//////////////////////////
// Authentication/Login //
//////////////////////////

export type TokenPayload = {
  token: string;
  expires: string | false; // date string or false to mean never expires
  expiresDate?: Date; // not returned from api but hydrated
};

// response for login or refresh token
export type LoginResponse = {
  accessToken: string;
  expires: string;
  expiresInMs: number;
};

///////////////////////////////
// Per-clinic Filter Options //
///////////////////////////////

// format of results from /api/v1/recommendation/filters?clinicId=
// not currently used
export type ClinicFiltersRaw = {
  searchTokens: string[];
  dates: string[];
};

export type ClinicFiltersRawResponse = {
  // ignoring lvl1 which appears to be array of { clinic _id, name, prettyname }
  lvl2: Record<string, ClinicFiltersRaw>; // where key === clinic ID
};

// type as massaged by getFilterDropdownOptions
export type ClinicFilters = {
  clinics: { title: string; value: string }[];
  dates: string[];
  locations: string[];
};

export type ClinicStatFilterNamedData = {
  name: string;
  prettyName: string;
};

export type ClinicStatFilterNamedDataWithId = ClinicStatFilterNamedData & {
  _id: string;
};

export type ProviderLocation = NamePrettyName;

// Basic provider info as assembled from user and user.provider by the backend
export type ProviderBasic = NamePrettyName & {
  _id: string;
  locations: ProviderLocation[];
  company: Company;
};

export type ClinicStatFilterResponse = {
  lvl1: {
    clinicId: string;
    name: string;
    prettyName: string;
  };
  lvl2: {
    clinicId: string;
    payor: ClinicStatFilterNamedDataWithId[];
    provider: ProviderBasic[]; // _id === User._id for the provider
  };
}[];

export type Permission = {
  action: string;
  subject: string;
};

export type Role = {
  name: string;
  prettyName: string;
  privileged?: boolean;
  permissions: Permission[];
};

export type ProviderIdentifier = {
  IDType: string;
  ID: string;
};

export type Provider = {
  description: string;
  Identifiers?: ProviderIdentifier[];
  IsActive: boolean;
  clinic?: string | null;
  locations?: ProviderLocation[];
};

export type CareCounselor = {
  isActive: boolean;
};

export const AllFlaglerTypes = ['customer', 'flagler'] as const;
export type FlaglerType = (typeof AllFlaglerTypes)[number];

export type User = {
  _id: string;
  clinicRoles: { name: string; prettyName: string }[];
  company: string; // id
  flaglerType: FlaglerType;
  name: string;
  email: string;
  trackRtmTime: boolean;
  username: string;
  isActive: boolean;
  permissions: Permission[]; // assumed
  provider?: Provider;
  careCounselor?: CareCounselor;
  roles: Role[];
  synonymousNames: string[];
};

export type CompanyBasic = {
  _id: string;
  name: string;
  prettyName: string;
};

export type Company = CompanyBasic & {
  type: 'Internal' | 'Standalone' | 'MSO' | 'MedTech';
  clinics: string[];
  flaglerType: 'customer' | 'flagler';
};

export const AllCompanyFeatureFlags = [
  'testFlag',
  'export',
  'recLifecycle',
  'inlineCommentUi',
  'rtmPatientReplySimulate',
  'flaglerAdmin',
  'medtechOnly',
  'bhi',
] as const;

export type CompanyFeatureFlagKey = (typeof AllCompanyFeatureFlags)[number];

export type CompanyFeatureFlags = Record<CompanyFeatureFlagKey, boolean>;

/////////
// RTM //
/////////

export const AllRtmChatStatus = ['PENDING', 'ENROLLED', 'DECLINED'] as const;

export type RtmChatStatus = (typeof AllRtmChatStatus)[number];

export const AllRtmTreatments = ['RTM', 'CCM', 'SCHEDULE'] as const;

export type RtmTreatment = (typeof AllRtmTreatments)[number];

export type RtmChatGroup = {
  _id: string;
  name: string;
};

export const AllContactClinicPriorityValues = [
  'routine',
  'urgent',
  'emergency',
];

export type ContactClinicPriority =
  (typeof AllContactClinicPriorityValues)[number];

export const AllChatFlagKeys = [
  'unresponsive',
  'isLandline',
  'callOnly',
  'spanishLanguage',
];

export type ChatFlagKey = (typeof AllChatFlagKeys)[number];

export const BoolChatFlagKeys: ChatFlagKey[] = [
  'isLandline',
  'spanishLanguage',
]; // this is used where callOnly needs to be handled separately because it can be undefined, true, or false

export const CarePlanBodyPartValues = ['back', 'knee', 'neck', 'shoulder'];

export type CarePlanBodyParts = (typeof CarePlanBodyPartValues)[number];

export type CarePlanCheckIn = {
  week: number;
  mood: string;
  painNotes: string;
  painScore: number | null | undefined;
  walkingNotes: string;
  stretchingNotes: string;
  rangeOfMotionChange: string;
  nextWalkingDuration?: string;
  nextWalkingFrequency?: string;
  nextStretchingFrequency: string;
  createdAt: string;
};

export const CarePlanTabValues = ['setup', 'checkins'];

export type CarePlanTab = (typeof CarePlanTabValues)[number];

export type CarePlan = {
  tab: CarePlanTab;
  step: number;
  primaryLocation: CarePlanBodyParts | null;
  initialPainScore: number | null | undefined;
  secondaryLocations: CarePlanBodyParts[];
  painReliever: string;
  activityDifficulty: string;
  hobbyDifficulty: boolean;
  walkingSafety: string;
  walkingPain: string;
  walkingDuration?: string;
  walkingFrequency?: string;
  stretchingFrequency?: string;
  programPainNotes: string;
  programHobbyDifficulty: string;
  walkingDifficulty: string;
  checkIns: CarePlanCheckIn[];
};

export type Chat = {
  object: string;
  _id: string;
  createdAt: string;
  updatedAt: string;
  name: string;
  firstName: string;
  preferredName: string;
  lastName: string;
  url: string;
  preview: string;
  lastMessageAt: string;
  unreplied: boolean;
  missedCall: boolean;
  blocked: boolean;
  unresponsive: boolean;
  isStarred: boolean;
  outreachInterval: string;
  textDue: boolean;
  callDue: boolean;
  outreachCallDue: boolean;
  treatment: [RtmTreatment];
  status: RtmChatStatus;
  declinedReason: string;
  group: RtmChatGroup;
  assignee: {
    name: string;
    avatarUrl: string;
    _id: string;
  };
  clinic: {
    _id?: string; // assume uuid
    prettyName: string;
    name: string;
    rtmPhoneNumber: string;
    phone: string;
  };
  doctorName?: string;
  patient?: string; // assume uuid
  notes?: string;
  phone?: string;
  callOnly?: boolean;
  isLandline?: boolean;
  spanishLanguage?: boolean;
  contactClinic?: ContactClinicPriority | null;
  contactClinicDate?: string;
  contactClinicReason?: string;
  dob?: string;
  provider?: {
    _id: string;
    name: string;
  };
  nextAppointmentAt?: string;
  availability?: string;
  carePlan?: CarePlan;
};

export type Call = {
  _id: string;
  recordingUrl: string;
  duration: number;
  transcript: string;
  status: string;
  inbound: boolean;
  answeredBy: string;
  transcriptStatus: string;
  createdAt: string;
};

export type Message = {
  object: string;
  _id: string;
  sending: boolean;
  createdAt: string;
  text: string;
  tempId: string;
  chat: string;
  call: Call;
  treatment: string;
  sender: {
    name: string;
    avatarUrl: string | undefined;
    id: string;
  };
  patient: {
    id: string;
    name: string;
  };
};

export type Timer = {
  active: boolean;
  startedAt: string;
  user: {
    _id: string;
    name: string;
    avatarUrl: string;
  };
  totalTime: number;
  chat: string;
};

export type Group = {
  object: string;
  _id: string;
  name: string;
  size: number;
};

export type Prompt = {
  object: string;
  _id: string;
  suggestionPrompt: string;
  summarizationPrompt: string;
  callSummaryPrompt: string;
  model: string;
};

export type Batch = {
  object: string;
  _id: string;
  sent: boolean;
  message: string;
  status: 'PENDING' | 'ENROLLED';
  sendAt?: string;
  repeatInterval?: string;
  sentCount: number;
  repliedCount: number;
  declinedCount: number;
  clinic: string | null;
  group: string | null;
  assignee: string | null;
  spanishLanguage: boolean;
  treatment: string[];
};

export type ChatStats = {
  enrolledCount: number;
  newCount: number;
  declinedCount: number;
  missedCallCount: number;
  unreadCount: number;
  starredCount: number;
  textDueCount: number;
  callDueCount: number;
  outreachCallDueCount: number;
};

export type ClinicAlert = {
  _id: string;
  priority: string;
  comment: string;
  status: string;
  createdAt: string;
  clinic: {
    _id?: string; // assume uuid
    prettyName: string;
    name: string;
  };
  chat: {
    _id: string;
    name: string;
    patient: {
      attributesKey: [
        {
          k: string;
          v: string;
        }
      ];
      attributes: [
        {
          k: string;
          v: string;
        }
      ];
    };
  };
  user: {
    _id: string;
    name: string;
    avatarUrl: string;
  };
};
