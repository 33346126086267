import axios from 'axios';
import { useLoginStore } from '@/stores/LoginStore';
import { toastError } from '@/utils/toast';

const axiosIns = axios.create({
  // You can add your headers here
  // ================================
  // baseURL: 'https://some-domain.com/api/',
  // timeout: 1000,
  // headers: {'X-Custom-Header': 'foobar'}
  headers: { 'client-user-id': 'abc' },
});

axiosIns.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    // if the error is a 403 AND the error message is that the token is invalid
    // then assume that something has gone wrong with the token
    if (
      403 === error.response?.status &&
      error.response?.data?.error?.includes('expired or invalid token')
    ) {
      // if the request was made with an auth token that doesn't match the auth
      // token we have in local storage, assume that the token was updated
      // after we made the request by some other window; LoginStore will try
      // again if necessary in one second.
      if (
        error.config?.headers?.Authorization !==
          `Bearer ${localStorage.getItem('accessToken')}` &&
        (error.config?.url ?? '').endsWith('auth/refresh')
      ) {
        console.warn('Tried to refresh with stale token');
      } else {
        // the token is bad and matches what we have in local storage; there's
        // no way to get a good token now, so log out
        toastError('You are not logged in or your login has expired.');
        useLoginStore().logout(undefined, true, 'Login expired or was invalid');
      }
    } else {
      // it's still possible to get 403s if, for example, FE calls some endpoint
      // and the user is not permitted to use it via a feature flag
      const message =
        error.response?.data?.error ??
        error.message ??
        'There was an error from the server';
      toastError(message);
    }
    return Promise.reject(error);
  }
);

// there is no hint in the backend's paths whether an endpoint requires
// authentication or not (i.e. api calls do not start with /api), so we
// whitelist

const unauthenticatedPaths = [
  // front end update detection file
  '/build-id',
  // from backend's route.js:
  '/auth/login',
  '/auth/workos/login',
  '/auth/workos/callback',
  '/auth/microsoft/redirect',
  '/auth/microsoft/signin',
  '/auth/flagler/login',
  '/auth/google/redirect',
  '/auth/google/signin',
  '/user/passwordreset',
  '/user/updatepassword',
  '/patient-form',
];

function matchesRootPaths(urlString, rootPaths) {
  try {
    const url = new URL(urlString);
    const path = url.pathname;
    return rootPaths.some((rootPath) => {
      const regex = new RegExp(`^${rootPath}(\/|\\?|$)`);

      return regex.test(path);
    });
  } catch (_e) {
    return true;
  }
}

axiosIns.interceptors.request.use(
  function (config) {
    let abortAndLogout = false;
    const onAuthenticatedPath = !matchesRootPaths(
      config.url,
      unauthenticatedPaths
    );
    // Do something before request is sent
    if (config.headers?.Authorization && onAuthenticatedPath) {
      if (useLoginStore().getAccessTokenNowExpired()) {
        console.error(`attempt to use expired token: ${config.url}`);
        toastError('Authentication token has expired.');
        abortAndLogout = true;
      }
    } else {
      if (onAuthenticatedPath) {
        // doesn't have auth header but should

        console.error(`missing token: ${config.url}`);
        toastError('Authentication token is missing');
        abortAndLogout = true;
      }
    }
    if (abortAndLogout && false) {
      const controller = new AbortController();
      controller.abort();
      useLoginStore().logout(undefined, true, 'Login expired or missing');
      return {
        ...config,
        signal: controller.signal,
      };
    }
    return config;
  },
  function (error) {
    // Do something with request error
    return Promise.reject(error);
  }
);

export default axiosIns;
