import { setupLayouts } from 'virtual:generated-layouts';
import { createRouter, createWebHistory } from 'vue-router';
import { getUserData } from './utils';
import { isUserLoggedIn } from '@/utils/user';

import routes from '~pages';
import { canNavigate } from '@layouts/plugins/casl';
import { useFeatureStore } from '@/stores/FeatureStore';
import { useLoginStore } from '@/stores/LoginStore';
import { usePreferencesStore } from '@/stores/PreferencesStore';

import { onAnyUnauthenticatedPage } from '@/utils/onAnyUnauthenticatedPage';
import { setWindowTitle } from '@/utils/windowTitle';
import { forceRefreshIfNeeded } from '@/utils/forceRefreshIfNeeded';

import { toastInfo } from '@/utils/toast';

const DEFAULT_ROUTE_TO_HOME = { name: 'patient-tracking' };

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes: [...setupLayouts(routes)],
  scrollBehavior() {
    return { top: 0 };
  },
});

router.beforeEach((to, from, next) => {
  const isLoggedIn = isUserLoggedIn();
  const canNavigateTo = canNavigate(to);

  // remove reload-time query param if we have it (to prevent bookmarking it)
  // show message saying that we have updated
  if (to.query['reload-time']) {
    delete to.query['reload-time'];
    return next(to);
  }

  if (to.query['flash']) {
    const message = to.query['flash'];
    delete to.query['flash'];
    toastInfo(message);
    return next(to);
  }

  forceRefreshIfNeeded(to.path, to.query);

  // Redirect to login if not logged in
  if (!onAnyUnauthenticatedPage(to.path)) {
    const loginStore = useLoginStore();

    if (!isLoggedIn) {
      loginStore.redirectToRoute = to.fullPath;
      // localStorage.setItem('redirect', to.fullPath);
      return next({ name: 'login' });
    } else {
      loginStore.redirectToRoute = null;
      const preferencesStore = usePreferencesStore();
      preferencesStore.lastVisitedPath = to.fullPath;
      // localStorage.removeItem('redirect');
    }
  }
  // we redirect here b/c we can't use feature store except from w/in component
  if (to.path === '/') {
    return next(useFeatureStore().getHomeRoute());
  }

  if (!canNavigateTo) {
    // If logged in => not authorized
    if (to.path !== '/not-authorized') {
      console.log(`redirecting to '/not-authorized'`);
      return next({ name: 'not-authorized' });
    }
  }

  // Redirect if logged in
  if (to.meta.redirectIfLoggedIn && isLoggedIn) {
    console.log(`redirectIfLoggedIn is true for ${JSON.stringify(to.meta)}`);
    console.log(
      `redirecting to true for ${JSON.stringify(
        useFeatureStore().getHomeRoute()
      )}`
    );
    return next(useFeatureStore().getHomeRoute());
  }

  setWindowTitle(to.meta?.title);
  return next();
});
export default router;
