/**
 * LoginStore
 *
 * Handles:
 *
 * - Hydrating access token on app client start up
 * - Refreshing access token before it expires
 * - Logging in with email/password or with access token
 * - Getting user profile after login
 * - Getting user abilities after login
 * - Logging out and removing stored tokens and user data from local storage
 *
 */

import { LoginResponse, TokenPayload } from '@/types/Api';
import { defineStore } from 'pinia';
import {
  performPostToBackend,
  refreshToken,
  getUserProfile,
} from '@/flagler-api/backend-api.js';
import { getUserFEAbilities } from '@/flagler-api/auth.js';
import { useGlobalAbility } from '@/composables/globalAbility';
import { usePreferencesStore } from './PreferencesStore';
import { initialAbility } from '@/plugins/casl/ability';

let refreshAccessTokenTimerId: ReturnType<typeof setInterval> | undefined =
  undefined;
let logoutTimeoutId: ReturnType<typeof setTimeout> | undefined = undefined;
let warnLogoutTimeoutId: ReturnType<typeof setTimeout> | undefined = undefined;

import { useRoute } from 'vue-router';
import router from '@/router';
import { useClinicStore } from './ClinicStore';
import { useCompanyStore } from './CompanyStore';
import { usePusherStore } from './PusherStore';
import { useUserStore } from './UserStore';

import { calcTokenExpired } from '@/utils/user';
import { dateStringToServerDateString } from '@/utils/date';
import { useBroadcastStore } from '@/stores/BroadcastStore';
import { useWebLock } from '@/composables/webLock';

import { toastInfo } from '@/utils/toast';

interface State {
  accessToken?: TokenPayload;
  // we don't actually have a separate refresh token; however we just keep it
  // in sync with the access token and use it, in case some day we do
  refreshToken?: TokenPayload;
  redirectToRoute: string | null;
}

const REFRESH_TOKEN_BUFFER_MS = 90000;

function calcMsToRefresh(d: Date | undefined, bufferMs?: number) {
  return (
    (d?.getTime() ?? 0) -
    Date.now() -
    (bufferMs === undefined ? REFRESH_TOKEN_BUFFER_MS : bufferMs)
  );
}

const { lockFunction } = useWebLock();

export const useLoginStore = defineStore('LoginStore', {
  state: (): State => {
    return {
      // email: null,
      // name: null,
      accessToken: undefined,
      refreshToken: undefined,
      redirectToRoute: null,
    };
  },
  getters: {
    // refresh 90 seconds before token expires; bg tabs may only have timer
    // resolution of 60 seconds
    // reminder: this computed value gets cached and only changes if the access
    // token changes
    msToRefresh: (state): number =>
      calcMsToRefresh(state.accessToken?.expiresDate),
  },
  actions: {
    // Two things can happen on startup
    // 1. user has no local storage token, or has expired local store refresh
    //    token: should redirect to login page
    // 2. user has local storage token and unexpired local store refresh token:
    //    should refresh the auth token, and only redirect if that fails
    startup() {
      const route = useRoute();

      this.setStoredTokenFromLocalStorage();

      if (!calcTokenExpired(this.refreshToken?.expiresDate)) {
        // we have a valid token; no need to login, so populate clinics now
        useClinicStore().populateClinics();
        useCompanyStore().startup();

        const userStore = useUserStore();
        userStore.startup();
        const { userData } = userStore;

        const clinicDataString = localStorage.getItem('userClinicsD');
        if (userData && clinicDataString) {
          const clinicData = JSON.parse(clinicDataString);
          const userId = userData._id;
          const companyId = userData.companyId;
          const clinicIds = Object.keys(clinicData);

          usePusherStore().startup(userId, clinicIds, companyId);
        } else {
          console.error('missing userData or userClinicsD');
        }

        // technically we could wait until the token was due to expire but we
        // refresh now in case the user should no longer have access
        return this.refreshAccessToken();
      } else {
        return Promise.reject(new Error('refresh token expired or missing'));
      }
    },
    loginWithEmailAndPassword(email: string, password: string) {
      return this.login(email, password);
    },
    loginWithToken(token: string, expiresDate?: Date) {
      return this.login(undefined, undefined, {
        token: token,
        expires:
          expiresDate === undefined
            ? false
            : (dateStringToServerDateString(expiresDate) as string),
        expiresDate: expiresDate,
      });
    },

    // authenticates via email/password or auth token, gets & sets user profile
    // and abilities, starts token refresh timer
    async login(email?: string, password?: string, accessToken?: TokenPayload) {
      const timeZone =
        Intl.DateTimeFormat().resolvedOptions().timeZone || 'America/New_York';

      if (accessToken) {
        this.storeToken(accessToken.token);
      }

      if (accessToken == undefined || accessToken.expiresDate === undefined) {
        // this is an email/pw login or a login token w/o an expiration

        // normally a login failure would have an error response like 401
        // in our case, we are getting 200 and BE is just not setting the token
        // if there is a problem
        // ADDITIONALLY BE might let you log in w/o a company association (as
        // when you've logged in successfully and have local storage accessToken)
        // but you should not be taken past the log in page if there is an error
        // when fetching profile; in this case you will get a 400 error for that
        // step
        const response =
          accessToken === undefined
            ? await performPostToBackend('/auth/login', {
                email,
                password,
                timeZone,
              })
            : await refreshToken();

        if (!response.data?.accessToken) {
          // this is a faux error object since the BE doesn't actually return an
          // error
          throw { code: 401, message: 'Incorrect email or password' };
        }
        const data = response.data;
        this.storeResponseAndStartTimer(data);
      } else {
        // we have a token w/ expiration, i.e. a normal auth token
        // make a fake login response
        this.storeResponseAndStartTimer({
          accessToken: accessToken.token,
          expires: dateStringToServerDateString(
            accessToken.expiresDate
          ) as string,
          expiresInMs: accessToken.expiresDate.getTime() - new Date().getTime(),
        });
      }

      const { data: profile } = await getUserProfile();
      const clinicStore = useClinicStore();

      clinicStore.clearClinics();
      localStorage.setItem('userCompany', JSON.stringify(profile?.company));
      localStorage.setItem('userClinicsD', JSON.stringify(profile?.clinicsD));

      // Although it is trivial to get user's timezone from browser, we still
      // might want to know what the backend thinks the user's timezone is
      localStorage.setItem('userTimeZone', profile?.timeZone);

      let userAbilities = [
        ...profile?.roles?.flatMap((role: any) => role.permissions),
        ...profile?.permissions,
      ];

      // VueAuth is the most default ability that allows access to all pages,
      // unless special ability is required. This is added because BE abilities
      // does not specify { action: 'read', subject: '...' } for each resource
      // (the default is that the token has access to resource unless ability is
      // specified). In Vue page, we need to specify yaml meta block with
      // action/subject. That default is { action: 'read', subject: 'VueAuth' }
      const extraFEabilities = await getUserFEAbilities();
      userAbilities = userAbilities.concat(extraFEabilities);

      localStorage.setItem('userAbilities', JSON.stringify(userAbilities));
      const ability = useGlobalAbility();
      ability?.update(userAbilities);

      const preferencesStore = usePreferencesStore();

      preferencesStore.userId = profile?._id;

      const userStore = useUserStore();
      const { userData } = storeToRefs(userStore);

      userData.value = {
        _id: profile?._id,
        fullName: profile?.name,
        avatarUrl: profile?.avatarUrl,
        email: profile?.email,
        companyId: profile?.company?._id,
        trackRtmTime: profile?.trackRtmTime,
        roles: profile?.roles.map((role: any) => role.name),
        abilities: userAbilities, // TODO this should be unnecessary, but some template code might still be dependent on it
        createdAt: profile?.createdAt,
      };

      let clinicIds: string[] = [];
      if (profile?.company?.clinics) {
        await clinicStore.populateClinics(profile?.company?.clinics);
        clinicIds = profile.company.clinics.map((c: any) => c._id);
      }

      // Update Sentry

      userStore.startup();
      useCompanyStore().startup();
      usePusherStore().startup(profile?._id, clinicIds, profile?.company?._id);
      useBroadcastStore().login.send('login');
      // const redirect = localStorage.getItem('redirect');
      // if (redirect) {
      //   localStorage.removeItem('redirect');
      //   router.replace(redirect);
      // }
    },

    logout(path?: string, broadcast = true, message?: string) {
      router.replace('/logout').then(() => {
        this.cancelRefreshTimer();
        const { userData } = storeToRefs(useUserStore());
        userData.value = undefined;

        // Remove "accessToken" from localStorage
        localStorage.removeItem('accessToken');
        localStorage.removeItem('accessTokenExpiry');
        localStorage.removeItem('accessTokenTimespan');

        // Remove "userAbilities" from localStorage
        localStorage.removeItem('userAbilities');

        useClinicStore().clear();
        useCompanyStore().clear();
        usePusherStore().shutdown();
        // Reset ability to initial ability
        const ability = useGlobalAbility();
        ability?.update(initialAbility);
        if (broadcast) {
          useBroadcastStore().login.send('logout');
        }
        if (message) {
          toastInfo(message);
        }

        router.push(path ?? '/login');
      });
    },

    setStoredTokenFromLocalStorage() {
      const accessToken = localStorage.getItem('accessToken');
      const expires = localStorage.getItem('accessTokenExpiry');
      if (accessToken && expires) {
        this.accessToken = {
          token: accessToken,
          expires,
          expiresDate: expires ? new Date(expires) : new Date(),
        };
        this.refreshToken = { ...this.accessToken };
      }
    },

    storeToken(token: string) {
      localStorage.setItem('accessToken', token);
    },
    storeResponseAndStartTimer(d: LoginResponse) {
      this.accessToken = {
        token: d.accessToken,
        expires: d.expires,
        expiresDate: new Date(d.expires),
      };
      this.refreshToken = { ...this.accessToken };
      this.storeToken(this.accessToken.token);
      localStorage.setItem(
        'accessTokenExpiry',
        this.accessToken.expires as string
      );
      localStorage.setItem('accessTokenTimespan', d.expiresInMs.toString());
      this.startRefreshTimer();
    },
    async refreshAccessToken(onlyIfNeeded = false) {
      return lockFunction('refreshAccessToken', async () => {
        let actuallyRefresh = !onlyIfNeeded;
        if (onlyIfNeeded) {
          this.setStoredTokenFromLocalStorage();
          const nowMsToRefresh = calcMsToRefresh(this.accessToken?.expiresDate);
          if (nowMsToRefresh <= 0) {
            if (nowMsToRefresh > -REFRESH_TOKEN_BUFFER_MS) {
              actuallyRefresh = true;
            } else {
              console.warn(
                'Token refresh timer called after token known to be expired; logging out'
              );
            }
          }
        }
        if (actuallyRefresh) {
          return refreshToken()
            .then((data: any) => {
              if (data) {
                this.storeResponseAndStartTimer(data.data);
              }
            })
            .catch((err: any) => {
              console.error(err);
              // if refresh token fails, either it has expired or been fooled with
              if (err.response && err.response.status === 403) {
                this.logout();
              }
            });
        } else {
          return Promise.resolve();
        }
      });
    },
    startRefreshTimer() {
      this.cancelRefreshTimer();
      if (this.refreshToken) {
        if (this.msToRefresh <= 0) {
          return this.refreshAccessToken();
        } else {
          refreshAccessTokenTimerId = setInterval(() => {
            // will only refresh if needed
            this.refreshAccessToken(true);
          }, 1000);
          return Promise.resolve();
        }
      } else {
        return Promise.reject(new Error('no refresh token'));
      }
    },
    cancelRefreshTimer() {
      if (refreshAccessTokenTimerId !== undefined) {
        clearTimeout(refreshAccessTokenTimerId);
        refreshAccessTokenTimerId = undefined;
      }
    },
    cancelLogoutTimer() {
      if (logoutTimeoutId !== undefined) {
        clearTimeout(logoutTimeoutId);
        logoutTimeoutId = undefined;
      }
    },
    cancelWarnLogoutTimer() {
      if (warnLogoutTimeoutId !== undefined) {
        clearTimeout(warnLogoutTimeoutId);
        warnLogoutTimeoutId = undefined;
      }
    },
    startLogoutTimer(f: () => void, ms: number) {
      this.cancelLogoutTimer();
      logoutTimeoutId = setTimeout(f, ms);
    },
    startWarnLogoutTimer(f: () => void, ms: number) {
      this.cancelWarnLogoutTimer();
      warnLogoutTimeoutId = setTimeout(f, ms);
    },
    getAccessTokenNowExpired() {
      if (this.accessToken?.expires === false) {
        return false;
      } else {
        return calcMsToRefresh(this.accessToken?.expiresDate, 0) <= 0;
      }
    },
  },
});
