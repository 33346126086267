import { performPostToBackend } from './backend-api';
import { useRtmStore } from '@/stores/RtmStore';
import { Timer } from '@/types/Api';

const startTimer = async (chatId: string | undefined) => {
  const trackRtmTime = JSON.parse(
    localStorage.getItem('userData') || 'null'
  )?.trackRtmTime;
  if (!chatId || !trackRtmTime) return null;
  const store = useRtmStore();
  const response = await performPostToBackend(`/timer/start`, {
    chatId,
    treatment: store.treatment,
  });
  const timer: Timer = response.data;
  store.upsertTimers(timer);
  return timer;
};

const stopTimer = async (chatId: string | undefined) => {
  const trackRtmTime = JSON.parse(
    localStorage.getItem('userData') || 'null'
  )?.trackRtmTime;
  if (!chatId || !trackRtmTime) return null;
  const store = useRtmStore();
  const response = await performPostToBackend(`/timer/stop`, { chatId });
  const timer: Timer = response.data;
  store.upsertTimers(timer);
  return timer;
};

const getTimers = async (chatId: string | undefined) => {
  if (!chatId) return [];
  const store = useRtmStore();
  const response = await performPostToBackend(`/timer/logs`, { chatId });
  const timers: Timer[] = response.data;
  store.upsertTimers(timers);
  return timers;
};

export { startTimer, stopTimer, getTimers };
