import { Patient, PatientRaw, PrettyColumn } from '@/types/Api';
import { PatientUi, PatientUiAttribute } from '@/types/Ui/Patient';
import { Clinic } from '@/types/Ui';

const identifierTemplateRegex = /{([^}]+)}/g;

export function getIdentifierColumnsFromTemplateString(s: string) {
  const results: string[] = [];
  let match: RegExpExecArray | null;

  while ((match = identifierTemplateRegex.exec(s)) !== null) {
    results.push(match[1]);
  }
  return results;
}

export function renderIdentifierTemplateString(
  s: string,
  callback: (match: string) => string
): string {
  return s.replace(identifierTemplateRegex, (match: string, group1: string) => {
    return callback(group1);
  });
}

export function patientTitle(p: Patient, clinic: Clinic) {
  if (clinic.piiFormat?.identifier_template) {
    return renderIdentifierTemplateString(
      clinic.piiFormat.identifier_template,
      (c) => p[c] || ''
    );
  } else {
    return clinic.columns.identifier
      .map((c) => p[c.patientAttributesKey])
      .join(' ');
  }
}

export function rawPatientToPatient(r: PatientRaw) {
  const p: Patient = {
    _id: r._id,
    clinic: r.clinic,
    attributes: {},
    attributesKey: {},
    patientMeta: {
      careAlignment: r.careAlignment,
      activeChat: r.activeChat,
    },
  };
  for (const kvp of r.attributes) {
    p[kvp.k] = kvp.v;
    p.attributes![kvp.k] = kvp.v;
  }
  for (const kvp of r.attributesKey) {
    p[kvp.k] = kvp.v;
    p.attributesKey![kvp.k] = kvp.v;
  }
  return p;
}

export function patientToPatientUi(
  patient: Patient,
  clinic: Clinic
): PatientUi {
  const attributes: PatientUiAttribute[] = clinic.columns.notIdentifier.map(
    (c: PrettyColumn) => {
      return {
        title: c.prettyName,
        value: patient[c.patientAttributesKey] || '',
      };
    }
  );
  const splitAt = Math.ceil(clinic.columns.notIdentifier.length / 2);
  return {
    title: patientTitle(patient, clinic),
    columns: [attributes.slice(0, splitAt), attributes.slice(splitAt)],
    id: patient._id,
    raw: patient,
  };
}
