import {
  getFormattedRecommendationsTable,
  performGetToBackend,
  performPostToBackend,
} from './backend-api';

const PRETTY_TO_RAW_FIELD_MAP = {
  'Patient ID': 'patient',
  Tags: 'searchTokens',
  'Rec Date': 'date',
  'Physician Rec': 'recommmendation',
  Comment: 'comments',
};

export async function getRecommendations(params) {
  const selectedClinic = params.selectedClinic ? params.selectedClinic : null;
  const selectedDate = params.selectedDate ? params.selectedDate : null;
  const locationFilter = params.location;
  const sortedColumn = params.sortedColumn;
  const sortDirection = params.sortDirection;
  const formattedTable = await getFormattedRecommendationsTable(
    selectedClinic,
    selectedDate,
    locationFilter,
    sortedColumn,
    sortDirection
  );
  const {
    q = '',
    role = null,
    plan = null,
    status = null,
    perPage = 10,
    currentPage = 1,
  } = params ?? {};
  const queryLower = q.toLowerCase();
  let filteredRecommendations = formattedTable.filter((recommendation) => {
    let includeInResults = q === '';
    Object.keys(recommendation).forEach((k) => {
      if (
        recommendation[k] &&
        recommendation[k].toString().toLowerCase().includes(queryLower)
      ) {
        includeInResults = true;
      }
    });
    return includeInResults;
  });
  const totalPage = Math.ceil(filteredRecommendations.length / perPage)
    ? Math.ceil(filteredRecommendations.length / perPage)
    : 1;
  const totalRecommendations = filteredRecommendations.length;
  if (perPage) {
    const firstIndex = (currentPage - 1) * perPage;
    const lastIndex = perPage * currentPage;

    filteredRecommendations = filteredRecommendations.slice(
      firstIndex,
      lastIndex
    );
  }
  return {
    recommendations: filteredRecommendations,
    totalPage,
    totalRecommendations,
  };
}

const formatRecommendation = (
  recommendation,
  piiColumns,
  procedures,
  recommendationChoices
) => {
  const formattedRec = {};
  formattedRec.hiddenFields = {
    recommendation_id: recommendation._id,
    'Patient ID': recommendation.patient,
    procedures: [],
  };

  piiColumns.forEach((piiColumn) => {
    formattedRec[piiColumn.prettyName] =
      recommendation['patient'][piiColumn.patientAttributesKey];
  });

  if (
    recommendation.clinic.searchTokens &&
    recommendation.clinic.searchTokens.length > 0
  ) {
    formattedRec['Location'] = recommendation.clinic.searchTokens;
  }

  formattedRec['Rec Date'] = recommendation.dates.map(
    (date) => date.split('T')[0]
  );

  const getMatchingRecommendedProcedureInfo = (procedureName) => {
    const matchedProcedure = recommendation.recommendedProcedures.find(
      (procedure) => procedure.name === procedureName
    );
    if (matchedProcedure) {
      const procedureId = matchedProcedure._id;
      const matchingInfo = recommendation.recommendedProcedureInfo.find(
        (info) => info.recommendedProcedure === procedureId
      );
      return matchingInfo || { summary: '' };
    }
    return { summary: '' }; // Procedure not found in recommendedProcedures
  };

  procedures.forEach((procedure) => {
    formattedRec[procedure.name] = {
      prettyName: procedure.prettyName,
      name: procedure.name,
      isRecommended: false,
      recommendedProcedureInfo: getMatchingRecommendedProcedureInfo(
        procedure.name
      ),
    };
  });

  recommendationChoices.forEach((choice) => {
    // add rec value to hidden fields for dropdown
    formattedRec.hiddenFields.procedures.push({
      title: choice.name,
      value: choice.name,
    });
  });

  recommendation.recommendedProcedures.forEach((recommendedProcedure) => {
    // formattedRec[recommendedProcedure.name].isRecommended = true; // this causes error on TPC for prod env for some reason?
    if (formattedRec[recommendedProcedure.name]) {
      // If formattedRec[recommendedProcedure.name] already exists, set isRecommended to true
      formattedRec[recommendedProcedure.name].isRecommended = true;
    } else {
      // If formattedRec[recommendedProcedure.name] doesn't exist, create it and set isRecommended to true
      formattedRec[recommendedProcedure.name] = { isRecommended: true };
    }
  });

  formattedRec['Physician Rec'] = recommendation.recommendation;

  formattedRec.Comment = '';
  if (recommendation.comments.length > 0) {
    formattedRec.Comment = recommendation.comments[0].text;
  }

  formattedRec['Procedure Ordered?'] = false;
  if (recommendation.status === 'ordered') {
    formattedRec['Procedure Ordered?'] = true;
  }

  // Replace NANs with blanks for formatting
  Object.keys(formattedRec).forEach((recKey) => {
    if (formattedRec[recKey] === 'nan') {
      formattedRec[recKey] = '';
    }
  });
  return formattedRec;
};

const getRawColumnFromPrettyColumn = (piiCols, sortedColumn) => {
  if (PRETTY_TO_RAW_FIELD_MAP[sortedColumn]) {
    return PRETTY_TO_RAW_FIELD_MAP[sortedColumn];
  } else {
    const col = piiCols.find((col) => col.prettyName === sortedColumn);
    if (col) {
      return col.patientAttributesKey;
    } else {
      return undefined;
    }
  }
};

const getFormattedDateOption = (date) => {
  return { title: date.split('T')[0], value: date };
};

const requestRecommendationExport = async (clinicId) => {
  return await performPostToBackend('/recommendation/export', {
    clinicIds: [clinicId],
  });
};

const getRecommendationExports = async (clinicId) => {
  return await performPostToBackend('/recommendation/export/search', {
    query: { clinic: { clinicIds: [clinicId] } },
    limit: -1,
  });
};

export {
  formatRecommendation,
  getRawColumnFromPrettyColumn,
  getFormattedDateOption,
  requestRecommendationExport,
  getRecommendationExports,
};
