import { defineStore } from 'pinia';

import { columnColors } from '@core/libs/apex-chart/apexChartConfig';

import { Clinic } from '@/types/Ui';

import {
  ClinicStatFilterNamedDataWithId,
  ClinicStatFilterResponse,
  Procedure,
  AllLifecycleFlows,
  PrettyColumn,
  ClinicExtra,
  ProviderBasic,
  Provider,
  ProviderLocation,
  User,
} from '@/types/Api';

import {
  getClinicData,
  getFilters,
  getClinicStatFilters,
  getUserProfile,
} from '@/flagler-api/backend-api';

import { getFormattedDateOption } from '@/flagler-api/recommendations';
import {
  getAllLifecycles,
  getAllLifecyclesForAllClinics,
} from '@/flagler-api/engagement';
import { adminGetClinics } from '@/flagler-api/clinics';
import { useGlobalAbility } from '@/composables/globalAbility';
import { usePreferencesStore } from './PreferencesStore';
// import { getIdentifierColumnsFromTemplateString } from '@/utils/patient';
import { getIdentifierColumnsFromClinic } from '@/utils/clinic';

const REFRESH_CLINIC_AFTER_MS = 15 * 60 * 1000; // 15 minutes

function populateClinicColumns(clinic: Clinic) {
  clinic.columns = { identifier: [], notIdentifier: [] };

  clinic.columns.identifier = getIdentifierColumnsFromClinic(clinic);

  if (clinic.piiFormat === undefined || clinic.piiFormat.rows === undefined) {
    clinic.columns.notIdentifier = clinic.piiColumns.filter(
      (c: PrettyColumn) => {
        return (
          !c.tags.some((t) => t.name === 'display_hidden') &&
          !clinic.columns.identifier.some((d: PrettyColumn) => {
            return d.patientAttributesKey === c.patientAttributesKey;
          })
        );
      }
    );
  } else {
    clinic.columns.notIdentifier = clinic.piiFormat.rows
      .filter((ic) => {
        const c = clinic.piiColumns.find((c) => c.patientAttributesKey === ic);
        if (c !== undefined) {
          return true;
        } else {
          console.warn(`Could not find notIdentifier column ${ic}`);
        }
      })
      .map((ic) => {
        const c = clinic.piiColumns.find((c) => c.patientAttributesKey === ic);
        if (c === undefined) {
          throw new Error(`Could not find notIdentifier column ${ic}`);
        }
        return c;
      });
  }
}

/*
// not currently used
function uniqueProviderLocationsForClinic(clinic: Clinic): ProviderLocation[] {
  return Object.values(
    clinic.providers.reduce((acc, provider) => {
      provider.locations.forEach((location) => {
        acc[location.name] = location;
      });
      return acc;
    }, {} as Record<string, ProviderLocation>)
  );
}
*/

interface State {
  clinicsById: Record<string, Clinic>;
  selectedClinicId: string | undefined;
  adminClinics: ClinicExtra[] | undefined;
  populatingClinics: boolean;
  havePopulatedClinics: boolean;
}

export const useClinicStore = defineStore('ClinicStore', {
  state: (): State => ({
    clinicsById: {},
    selectedClinicId: undefined,
    adminClinics: undefined,
    populatingClinics: false,
    havePopulatedClinics: false,
  }),
  getters: {
    allClinics(state): Clinic[] {
      return Object.values(state.clinicsById);
    },
    selectedClinic(state): Clinic | undefined {
      if (state.selectedClinicId) {
        return state.clinicsById[state.selectedClinicId];
      } else {
        return undefined;
      }
    },
    selectedClinicPrettyName() {
      if (this.selectedClinic) {
        return this.selectedClinic.prettyName;
      } else {
        return '';
      }
    },
  },
  actions: {
    // populateClinics() must be called first
    async getClinicById(clinicId: string) {
      const clinic: Clinic | undefined = this.clinicsById[clinicId];

      if (!clinic) {
        return undefined;
      }
      if (
        clinic.lastRefreshed === undefined ||
        new Date().getTime() - clinic.lastRefreshed > REFRESH_CLINIC_AFTER_MS
      ) {
        const clinicResponse = await getClinicData(clinicId);
        const clinicData = (clinicResponse.data ?? {}) as Partial<Clinic>;
        const rawFilters = await getFilters(clinicId);
        let dates = clinic.dates;

        if (rawFilters.data.lvl2[clinicId]?.dates) {
          dates = rawFilters.data.lvl2[clinicId].dates.map((date: any) =>
            getFormattedDateOption(date)
          );
        }

        clinic.lastRefreshed = new Date().getTime();
        if (clinicData.procedures) {
          // clinic data endpoint does not include procedure state groups
          clinicData.procedures = clinicData.procedures.map((p) => {
            const op = clinic.procedures.find((pp) => pp.name === p.name);
            return {
              ...p,
              stateGroupIndex: op?.stateGroupIndex ?? undefined,
            };
          });
        }
        populateClinicColumns(clinic);
        this.clinicsById[clinicId] = {
          ...clinic,
          ...clinicData,
          dates,
        };
      }
      return clinic;
    },
    /**
     * Populates all clinics with given data, or if none given, from
     * localStorage. localStorage data does NOT include dates (used to populate
     * date filter select control). Must be called after user permissions have
     * been set.
     *
     * @param {Clinic[]} withClinics Optionally provide clinic data. Could be
     *                               used with data returned from profile on
     *                               login.
     */
    async populateClinics(withClinics?: Clinic[]) {
      if (this.populatingClinics) {
        return;
      }
      this.populatingClinics = true;
      let clinics = withClinics;
      const storedClinicsById = localStorage.getItem('clinicsById');
      let newClinicsById: Record<string, Clinic> = storedClinicsById
        ? (JSON.parse(storedClinicsById) as Record<string, Clinic>)
        : { ...this.clinicsById };
      if (!clinics) {
        const company = localStorage.getItem('userCompany');

        if (company) {
          clinics = JSON.parse(company).clinics;
          if (clinics) {
            for (const clinicId of Object.keys(this.clinicsById)) {
              if (!clinics.find((c) => c._id === clinicId)) {
                delete newClinicsById[clinicId];
                delete this.clinicsById[clinicId];
              }
            }
          } else {
            newClinicsById = {};
          }
        }
      } else {
        // if we provide clinics, it's b/c we want to overwrite cached clinics
        newClinicsById = {};
      }

      if (!clinics) {
        this.populatingClinics = false;
        return;
      }
      const ability = useGlobalAbility();
      let allClinicLifecycles: Record<string, AllLifecycleFlows> = {};
      try {
        allClinicLifecycles = await getAllLifecyclesForAllClinics();
      } catch (error) {
        console.warn('Failed to get all clinic lifecycles', error);
      }

      for (const clinic of clinics) {
        const existingClinic = newClinicsById[clinic._id] ?? {};

        const flows =
          ability && ability.can('update', 'recLifecycle')
            ? // ? await getAllLifecycles(clinic._id)
              allClinicLifecycles[clinic._id] //await getAllLifecycles(clinic._id)
            : ({} as AllLifecycleFlows);

        populateClinicColumns(clinic);
        newClinicsById[clinic._id] = {
          ...existingClinic,
          ...clinic,
          ...{ flows },
        };
      }
      if (this.selectedClinicId === undefined) {
        const clinicIds = Object.keys(newClinicsById);
        if (clinicIds.length > 0) {
          const preferredClinicId = usePreferencesStore().preferredClinicId;
          if (preferredClinicId && clinicIds.includes(preferredClinicId)) {
            this.selectedClinicId = preferredClinicId;
          } else {
            this.selectedClinicId = clinicIds[0];
          }
        }
      }
      let statFilters: ClinicStatFilterResponse[] | undefined;
      try {
        const data = await getClinicStatFilters();
        statFilters = data.data;
      } catch (error) {
        console.warn('Failed to get clinic stat filters', error);
      }
      if (statFilters) {
        for (const data of statFilters) {
          if (data.lvl2 && newClinicsById[data.lvl1.clinicId]) {
            const clinicId = data.lvl1.clinicId;
            newClinicsById[clinicId].payors =
              data.lvl2.payor.sort(
                (
                  a: ClinicStatFilterNamedDataWithId,
                  b: ClinicStatFilterNamedDataWithId
                ) => a.prettyName.localeCompare(b.prettyName)
              ) ?? [];
            newClinicsById[clinicId].providers =
              data.lvl2.provider.sort(
                (
                  a: ClinicStatFilterNamedDataWithId,
                  b: ClinicStatFilterNamedDataWithId
                ) => a.prettyName.localeCompare(b.prettyName)
              ) ?? [];
            newClinicsById[clinicId].locations = data.lvl2.locations;
          }
        }
      }
      this.clinicsById = newClinicsById;
      localStorage.setItem('clinicsById', JSON.stringify(this.clinicsById));
      this.populatingClinics = false;
      this.havePopulatedClinics = true;
    },

    // call after a clinic is added or removed
    async repopulateClinicsFromUserProfile() {
      const { data: profile } = await getUserProfile();
      this.clearClinics();
      if (profile?.company?.clinics) {
        return await this.populateClinics(profile?.company?.clinics);
      }
    },

    async populateClinicsIfNecessary() {
      if (!this.havePopulatedClinics) {
        return this.populateClinics();
      }
    },

    async upsertClinic(clinic: Partial<Clinic>) {
      if (clinic._id) {
        const existingClinic = this.clinicsById[clinic._id] ?? {};
        this.clinicsById[clinic._id] = {
          ...existingClinic,
          ...clinic,
        };
        // also update the clinic in local storage
        const companyString = localStorage.getItem('userCompany');
        if (companyString) {
          const company = JSON.parse(companyString);
          if (company) {
            const clinicIndex = company.clinics.findIndex(
              (c: Clinic) => c._id === clinic._id
            );
            if (clinicIndex >= 0) {
              company.clinics[clinicIndex] = {
                ...company.clinics[clinicIndex],
                ...clinic,
              };
              localStorage.setItem('userCompany', JSON.stringify(company));
            }
          }
        }
      }
    },

    clearClinics() {
      this.clinicsById = {};
      this.selectedClinicId = undefined;
    },

    getClinicForClinicId(clinicId: string): Clinic | undefined {
      return this.clinicsById[clinicId];
    },

    getClinicForClinicIdOrSelectedClinic(
      clinicId?: string
    ): Clinic | undefined {
      if (clinicId === undefined) {
        clinicId = this.selectedClinicId;
      }
      if (clinicId === undefined) {
        return undefined;
      }
      const clinic = this.clinicsById[clinicId];
      return clinic;
    },

    getColorForProcedureAndClinic(procedureName: string, clinicId?: string) {
      const clinic = this.getClinicForClinicIdOrSelectedClinic(clinicId);
      if (!clinic) {
        return undefined;
      }
      const procedureColors: Record<string, string> =
        clinic.procedureColors ?? {};

      if (!procedureColors[procedureName]) {
        if (clinic.nextProcedureColorIndex === undefined) {
          clinic.nextProcedureColorIndex = 0;
        }
        const keys = Object.keys(columnColors);
        procedureColors[procedureName] =
          columnColors[keys[clinic.nextProcedureColorIndex]];
        clinic.procedureColors = procedureColors;
        clinic.nextProcedureColorIndex = clinic.nextProcedureColorIndex + 1;
        if (clinic.nextProcedureColorIndex >= keys.length) {
          clinic.nextProcedureColorIndex = 0;
        }
      }
      return procedureColors[procedureName] ?? '#ff0000';
    },
    getPrettyStateForProcedureAndClinic(
      stateName: string,
      procedureNameOrObject: undefined | string | Procedure,
      clinicId?: string
    ): string {
      const clinic = this.getClinicForClinicIdOrSelectedClinic(clinicId);

      if (!clinic) {
        return stateName;
      }
      const procedure =
        typeof procedureNameOrObject !== 'string'
          ? procedureNameOrObject
          : clinic.procedures.find((p) => p.name === procedureNameOrObject);
      if (clinic.flows !== undefined) {
        // if we have been given a procedure, use its flow
        if (
          procedure &&
          procedure.recommendationLifecyclePath !== undefined &&
          clinic.flows[procedure.recommendationLifecyclePath] !== undefined
        ) {
          const state = clinic.flows[
            procedure.recommendationLifecyclePath
          ].states.find((s) => s.state === stateName);
          if (state) {
            return state.prettyState;
          }
        } else {
          // just find the first flow that has the state
          for (const f of Object.values(clinic.flows)) {
            const state = f.states.find((s) => s.state === stateName);
            if (state) {
              return state.prettyState;
            }
          }
        }
      }
      return stateName;
    },
    getProviderFromId(
      providerId: string,
      clinicId?: string
    ): ClinicStatFilterNamedDataWithId | undefined {
      const clinic = this.getClinicForClinicIdOrSelectedClinic(clinicId);
      if (!clinic) {
        return undefined;
      }
      return clinic.providers?.find((p) => p._id === providerId);
    },
    getPayorFromId(
      providerId: string,
      clinicId?: string
    ): ClinicStatFilterNamedDataWithId | undefined {
      const clinic = this.getClinicForClinicIdOrSelectedClinic(clinicId);
      if (!clinic) {
        return undefined;
      }
      return clinic.payors?.find((p) => p._id === providerId);
    },
    async getAdminClinics() {
      if (this.adminClinics === undefined) {
        this.adminClinics = await adminGetClinics();
      }
      return this.adminClinics;
    },
    getAdminClinicForId(clinicId: string) {
      if (this.adminClinics === undefined) {
        return undefined;
      }
      return this.adminClinics.find((c) => c._id === clinicId);
    },
    upsertAdminClinic(clinic: ClinicExtra) {
      if (this.adminClinics === undefined) {
        return;
      }
      const existingClinic = this.adminClinics.find(
        (c) => c._id === clinic._id
      );
      if (existingClinic) {
        Object.assign(existingClinic, clinic);
      } else {
        this.adminClinics.push(clinic);
      }
    },

    updateUserProvider(user: User, oldClinicId?: string) {
      const provider = user.provider;
      // remove the provider from the old clinic if necessary
      if (oldClinicId && (!provider || provider.clinic !== oldClinicId)) {
        const clinic = this.clinicsById[oldClinicId];
        const i = clinic.providers.findIndex((p) => p._id === user._id);
        if (i >= 0) {
          clinic.providers.splice(i, 1);
        }
      }
      // add or update the provider in its current clinic
      if (provider?.clinic && this.clinicsById[provider.clinic]) {
        const clinic = this.clinicsById[provider.clinic];
        const i = clinic.providers.findIndex((p) => p._id === user._id);
        const basicProvider: ProviderBasic = {
          _id: user._id,
          name: user.name,
          prettyName: user.name,
          locations: provider.locations || [],
        };
        if (i >= 0) {
          clinic.providers[i] = basicProvider;
        } else {
          clinic.providers.push(basicProvider);
        }
      }
    },

    deleteAdminClinic(clinicId: string) {
      this.adminClinics = this.adminClinics?.filter((c) => c._id !== clinicId);
    },
    clear() {
      this.clinicsById = {};
      this.selectedClinicId = undefined;
      this.adminClinics = undefined;
      this.populatingClinics = false;
      this.havePopulatedClinics = false;
    },
  },
});
