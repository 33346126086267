import type { ClinicExtra } from '@/types/Api';

import {
  performPostToBackend,
  performGetToBackend,
  performPutToBackend,
  performDeleteToBackend,
} from '@/flagler-api/backend-api';

import { useClinicStore } from '@/stores/ClinicStore';

export async function adminGetClinics(): Promise<ClinicExtra[] | undefined> {
  const response = await performGetToBackend('/clinic/list/all');
  return response?.data;
}

export function getClinics() {
  const response = performPostToBackend('/clinic/search', {
    limit: 999,
    // query: {
    // 	clinicIds: []
    // }
  });
  return response;
}

export async function adminGetClinic(
  id: string
): Promise<ClinicExtra | undefined> {
  const response = await performPostToBackend('/clinic/list/search', {
    query: { clinicIds: [id] },
  });
  return response.data?.data?.[0];
}

export async function getClinic(id: string): Promise<ClinicExtra | undefined> {
  const response = await performPostToBackend('/clinic/search', {
    query: { clinicIds: [id] },
  });
  return response.data?.data?.[0];
}

export async function createClinic(
  c: ClinicExtra
): Promise<ClinicExtra | undefined> {
  const response = await performPostToBackend('/clinic', c);
  const newClinic = response.data;

  if (newClinic) {
    useClinicStore().upsertClinic(newClinic);
  }
  return newClinic;
}

export async function deleteClinic(clinicId: string) {
  return await performDeleteToBackend(`/clinic/list/${clinicId}`);
}

export async function adminUpdateClinic(
  c: ClinicExtra
): Promise<ClinicExtra | undefined> {
  const response = await performPutToBackend(`/clinic/list/${c._id}`, c);
  const updatedClinic = response.data;

  if (updatedClinic) {
    useClinicStore().upsertClinic(updatedClinic);
  }
  return updatedClinic;
}

export async function updateClinic(
  c: Partial<ClinicExtra>
): Promise<ClinicExtra | undefined> {
  const response = await performPutToBackend(`/clinic/${c._id}`, c);
  const updatedClinic = response?.data;

  if (updatedClinic) {
    useClinicStore().upsertClinic(updatedClinic);
  }
  return updatedClinic;
}
