import { User } from '@/types/Api';

export function calcTokenExpired(d: Date | undefined) {
  return !d || Date.now() > d.getTime();
}

export function isUserLoggedIn() {
  if (localStorage.getItem('userData') && localStorage.getItem('accessToken')) {
    const expires = localStorage.getItem('accessTokenExpiry');
    if (expires) {
      return !calcTokenExpired(new Date(expires));
    }
  }
  return false;
}

export function roleNamesIncludesProvider(roleNames: string[]) {
  return roleNames.includes('Provider');
}

export function isProvider(u: User): boolean {
  return roleNamesIncludesProvider(u.roles.map((r) => r.name));
}

export function roleNamesIncludesCareCounselor(roleNames: string[]) {
  return roleNames.includes('AdminRTM');
}

export function isCareCounselor(u: User): boolean {
  return roleNamesIncludesCareCounselor(u.roles.map((r) => r.name));
}
