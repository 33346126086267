function dateFromDateOrString(d: Date | string | null | undefined) {
  let date: Date | undefined = undefined;
  if (typeof d === 'string') {
    date = new Date(d);
  } else {
    date = d ?? undefined;
  }
  if (date && !isNaN(date.getTime())) {
    return date;
  } else {
    return undefined;
  }
}

/**
 * Given a string or a falsy value, returns a string of the date in the
 * preferred UI format, or an empty string if value passed could not be parsed
 * into a date.
 * @param {Date | null | string} d Value to turn into a date string
 */
export function dateToFormattedString(d: Date | null | string) {
  const date = dateFromDateOrString(d);
  if (date) {
    const yyyy = date.getFullYear().toString();
    const mm = (date.getMonth() + 1).toString().padStart(2, '0');
    const dd = date.getDate().toString().padStart(2, '0');
    return `${mm}/${dd}/${yyyy}`;
  } else {
    return '';
  }
}

export function dateTimeToFormattedString(d: Date | null | string) {
  const date = dateFromDateOrString(d);
  if (date) {
    const yyyy = date.getFullYear().toString();
    const mm = (date.getMonth() + 1).toString().padStart(2, '0');
    const dd = date.getDate().toString().padStart(2, '0');
    const time = date.toLocaleString('en-US', {
      hour: 'numeric',
      minute: 'numeric',
      hour12: true,
    });

    return `${mm}/${dd}/${yyyy} ${time}`;
  } else {
    return '';
  }
}

export function dateStringToServerDateString(
  d: Date | string | null | undefined
): string | undefined {
  const date = dateFromDateOrString(d);

  return date === undefined ? undefined : date.toISOString();
}

/**
 * Example: '2000-10-31T01:30:00.000-05:00' => '200010'
 * @param {string} s string in ISO DateTime format
 */
export function isoDateTimeStringToYyyyMm(s: string) {
  return s.substring(0, 4) + s.substring(5, 7);
}

/**
 * Example '200010' => { year: 2000, month: 10 }
 * @param {string} s YYYYMM string
 */
export function yyyyMmToYearMonth(s: string) {
  return {
    year: parseInt(s.substring(0, 4)),
    month: parseInt(s.substring(4, 6)),
  };
}

/**
 * Examples: '200012', 2 => '200102', '200012', -2 => '200010'
 * @param {string} s           YYYYMM string
 * @param {number} monthsToAdd # of months to add or substract
 */
export function addMonthsToYyyyMm(s: string, monthsToAdd: number) {
  let { year, month } = yyyyMmToYearMonth(s);
  month = month + monthsToAdd;

  // months are 1-based so let's not get fancy with the math
  while (month > 12) {
    month -= 12;
    year += 1;
  }
  while (month < 1) {
    month += 12;
    year -= 1;
  }
  return `${year.toString().padStart(4, '0')}${month
    .toString()
    .padStart(2, '0')}`;
}

const shortMonths = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sep',
  'Oct',
  'Nov',
  'Dec',
];
const longMonths = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];

/**
 * Example: '200010' => 'Oct 2000'
 * @param {string} s               YYYYMM string
 * @param {boolean} names = true   Use month names instead of numbers
 * @param {boolean} short = true   Use short month names instead of long
 */
export function yyyyMmToFormattedString(s: string, names = true, short = true) {
  const { year, month } = yyyyMmToYearMonth(s);
  const m = names
    ? (short ? shortMonths[month - 1] : longMonths[month - 1]) + ' '
    : month + '/';
  return `${m}${year}`;
}

/**
 * Example: '2000-10-01T00:00:00.000Z' => 'Oct 2000'
 * @param {string} s               ISO DateTime string
 * @param {boolean} names = true   Use month names instead of numbers
 * @param {boolean} short = true   Use short month names instead of long
 */
export function isoDateTimeStringToYearMonthString(
  s: string,
  names = true,
  short = true
) {
  return yyyyMmToFormattedString(isoDateTimeStringToYyyyMm(s), names, short);
}
