import { defineStore } from 'pinia';
import { useGlobalAbility } from '@/composables/globalAbility';

interface State {}

export const useFeatureStore = defineStore('FeatureStore', {
  state: (): State => ({}),
  actions: {
    getHomeRoute() {
      const ability = useGlobalAbility();
      if (ability) {
        if (ability.can('read', 'medtechOnly')) {
          return { name: 'overview-medtech' };
        } else if (ability.can('update', 'recLifecycle')) {
          return { name: 'overview' };
        } else if (ability.can('write', 'chat')) {
          return { name: 'chat' };
        }
      }
      return { name: 'payor-exclusion' };
    },
  },
});
