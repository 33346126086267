/**
 * The date time control we use, which is a wrapped version of flatpickr
 * included here as AppDateTimePicker, has some shortcomings when used in
 * dialogs, relating to its z-index and events being picked up by the dialog
 * scrim and counting as click-outs that close the dialog.
 *
 * Originally we only cared about the z-index, and we consumed events to prevent
 * the indcidentally triggering a dialog close.
 *
 * However, in consuming events, the month and year controls (select and input
 * elements) no longer worked. Several experimental solutions failed to provide
 * a satisfactory way to permit those controls to work while preventing the
 * dialog from closing (including not permitting the dialog to close if the
 * calendar was opened).
 *
 * So, the new solution allows the invoker to pass an element that is some
 * ancestor of the calendar for the purpose of finding Vuetify's overlay content
 * element (`.v-overlay__contenr`) and appending a container to it to house the
 * calendar control. This permits all the events to be left alone without ever
 * closing the dialog. However, the calendar is positioned programatically
 * somewhere inside flatpackr, relative to the viewport, which means it is not
 * positioned correctly as its container is now a child of the dialog contents.
 * We therefore position our container at an offset that should put it in the
 * upper right corner of the viewport, programmatically.
 *
 */

import {
  ref,
  MaybeRef,
  onMounted,
  onUnmounted,
  toValue,
  ComponentPublicInstance,
} from 'vue';

// we consume events so they don't propagate outside of a modal, e.g., which
// could close the modal
// function killClickEvent(e: Event) {
//   e.stopPropagation();
//   e.preventDefault();
// }

export function useCalendarSpecialContainer(
  useOverlayContent:
    | false
    | MaybeRef<HTMLElement | ComponentPublicInstance> = false
) {
  const overlayContainerEl = ref<HTMLDivElement | null | undefined>(undefined);
  let usingResizeEvent = false;
  let element: HTMLDivElement | undefined = undefined;
  let overlayContentEl: HTMLElement | undefined = undefined;

  function resize() {
    if (overlayContentEl && element) {
      const b = overlayContentEl.getBoundingClientRect();
      let left: string;
      let top: string;

      if (b.x > 0) {
        left = `-${b.x}px`;
      } else {
        left = `${-b.x}px`;
      }
      if (b.y > 0) {
        top = `-${b.y}px`;
      } else {
        top = `${-b.y}px`;
      }
      element.style.left = `-${b.x}px`;
      element.style.top = `-${b.y}px`;
    }
  }
  onMounted(() => {
    overlayContainerEl.value = null;
    if (useOverlayContent !== false) {
      const findOverlayContentFromEl = toValue(useOverlayContent);
      if (findOverlayContentFromEl) {
        const el =
          (findOverlayContentFromEl as ComponentPublicInstance).$el ||
          findOverlayContentFromEl;
        overlayContentEl = el.closest('.v-overlay__content');
        if (overlayContentEl) {
          element = document.createElement('div');
          element.classList.add('special-flatpickr-container');

          element.classList.add('fixed');

          overlayContentEl.append(element);
          overlayContainerEl.value = element;

          usingResizeEvent = true;
          window.addEventListener('resize', resize);
          resize();
        }
      }
    }
  });

  onUnmounted(() => {
    // overlayContainerEl.value?.removeEventListener(
    //   'pointerdown',
    //   killClickEvent
    // );
    if (usingResizeEvent) {
      window.removeEventListener('resize', resize);
    }
    overlayContainerEl.value?.remove();
  });
  // null means does not exist; undefined means not yet initialized
  return overlayContainerEl;
}
