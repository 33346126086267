// useAbility() doesn't like to be called outside of a startup script. To avoid
// having to pass around the Ability object returned from useAbility, this
// composable is intended to be called with an argument once when the app mounts
// so that other-non component scripts, e.g. stores, can call useGlobalAbility
// and get the same Object. Trying to call useGlobalAbility when it hasn't been
// inited from a component is an error.

import type { Ability } from '@casl/ability';
import { useAppAbility } from '@/plugins/casl/useAppAbility';

let ability: Ability | null = null;

export function useGlobalAbility(a?: Ability | null) {
  if (a || a === null) {
    ability = a;
  } else if (!ability) {
    return useAppAbility();
  }
  return ability;
}
