<template>
  <div v-if="visible" :id="id" :class="styles.control.root">
    <slot></slot>
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue';
import { Styles } from '../styles';

export default defineComponent({
  name: 'ControlWrapper',
  props: {
    id: {
      required: true as const,
      type: String,
    },
    visible: {
      required: false as const,
      type: Boolean,
      default: true,
    },
    styles: {
      required: true,
      type: Object as PropType<Styles>,
    },
  },
});
</script>
