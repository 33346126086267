<template>
  <control-wrapper
    v-bind="controlWrapper"
    :styles="styles"
    :is-focused="isFocused"
    :applied-options="appliedOptions"
  >
    <v-label
      v-if="computedLabel"
      :for="control.id + '-input'"
      v-bind="vuetifyProps('v-label')"
      >{{ computedLabel }}</v-label
    >
    <v-radio-group
      :id="control.id + '-input'"
      :class="styles.control.input"
      :disabled="!control.enabled"
      :autofocus="appliedOptions.focus"
      :placeholder="appliedOptions.placeholder"
      :hint="control.description"
      :persistent-hint="persistentHint()"
      :required="control.required"
      :error-messages="control.errors"
      :model-value="control.data"
      v-bind="vuetifyProps('v-radio-group')"
      @change="onChange"
      @focus="isFocused = true"
      @blur="isFocused = false"
    >
      <v-radio
        v-for="o in control.options"
        v-bind="vuetifyProps(`v-radio[${o.value}]`)"
        :key="o.value"
        :label="o.label"
        :model-value="o.value"
      ></v-radio>
    </v-radio-group>
  </control-wrapper>
</template>

<script lang="ts">
import {
  ControlElement,
  JsonFormsRendererRegistryEntry,
  rankWith,
  isEnumControl,
  optionIs,
  and,
} from '@jsonforms/core';
import { defineComponent } from 'vue';
import {
  rendererProps,
  useJsonFormsEnumControl,
  RendererProps,
} from '@jsonforms/vue';
import { default as ControlWrapper } from './JsonFormsControlWrapper.vue';
import { useVuetifyControl } from '@/jsonForms/utils';
import { VRadioGroup, VRadio, VLabel } from 'vuetify/components';

const controlRenderer = defineComponent({
  name: 'RadioGroupControlRenderer',
  components: {
    ControlWrapper,
    VRadioGroup,
    VRadio,
    VLabel,
  },
  props: {
    ...rendererProps<ControlElement>(),
  },
  setup(props: RendererProps<ControlElement>) {
    const vc = useVuetifyControl(useJsonFormsOneOfEnumControl(props));
    const originalOnChange = vc.onChange;

    return {
      ...vc,
      onChange(value: any) {
        // current onChange is receiving an event object instead of a value
        // we intercept the event object and extract the value and pass it along
        // otherwise it is busted; seems likely to break if jsonforms is
        // updated
        originalOnChange(value.target._value);
      },
    };
  },
});

export default controlRenderer;

export const entry: JsonFormsRendererRegistryEntry = {
  renderer: controlRenderer,
  tester: rankWith(20, and(isEnumControl, optionIs('format', 'radio'))),
};
</script>
