import '@/@fake-db/db';
import '@/@iconify/icons-bundle';
import App from '@/App.vue';
import ability from '@/plugins/casl/ability';
import layoutsPlugin from '@/plugins/layouts';
import vuetify from '@/plugins/vuetify';
import { loadFonts } from '@/plugins/webfontloader';
import router from '@/router';
import '@/styles/styles.scss';
import { abilitiesPlugin } from '@casl/vue';
import '@core/scss/template/index.scss';
import { createPinia } from 'pinia';
import piniaPluginPersistedstate from 'pinia-plugin-persistedstate';
import { createApp } from 'vue';
import * as Sentry from '@sentry/vue';

loadFonts();

const SENTRY_DSN =
  'https://9fd9a089da8743c7a006dabd4d2896cb@o4504430145306624.ingest.sentry.io/4504787871858688';

// Create vue app
const app = createApp(App);
const env = import.meta.env.VITE_ENVIRONMENT;

// Use plugins
app.use(vuetify);

const pinia = createPinia();
pinia.use(piniaPluginPersistedstate);
app.use(pinia);

app.use(router);
app.use(layoutsPlugin);
app.use(abilitiesPlugin, ability, {
  useGlobalProperties: true,
});

////////////////////////////////////////////////
// Register components that may be treeshaken //
////////////////////////////////////////////////

import JsonFormsBooleanControlRenderer from '@/components/JsonForms/JsonFormsBooleanControlRenderer.vue';
import JsonFormsControlWrapper from '@/components/JsonForms/JsonFormsControlWrapper.vue';
import JsonFormsDateControlRenderer from '@/components/JsonForms/JsonFormsDateControlRenderer.vue';
import JsonFormsEnumControlRenderer from '@/components/JsonForms/JsonFormsEnumControlRenderer.vue';
import JsonFormsMultiStringControlRenderer from '@/components/JsonForms/JsonFormsMultiStringControlRenderer.vue';
import JsonFormsStringControlRenderer from '@/components/JsonForms/JsonFormsStringControlRenderer.vue';
import JsonFormsTagControlRenderer from '@/components/JsonForms/JsonFormsTagControlRenderer.vue';
import JsonFormsGroupRenderer from '@/components/JsonForms/JsonFormsGroupRenderer.vue';
import JsonFormsOneOfRadioGroupControlRenderer from '@/components/JsonForms/JsonFormsOneOfRadioGroupControlRenderer.vue';
import JsonFormsRadioGroupControlRenderer from '@/components/JsonForms/JsonFormsRadioGroupControlRenderer.vue';
import JsonFormsNumberScaleRenderer from '@/components/JsonForms/JsonFormsNumberScaleRenderer.vue';

app
  .component('JsonFormsBooleanControlRenderer', JsonFormsBooleanControlRenderer)
  .component('JsonFormsControlWrapper', JsonFormsControlWrapper)
  .component('JsonFormsDateControlRenderer', JsonFormsDateControlRenderer)
  .component('JsonFormsEnumControlRenderer', JsonFormsEnumControlRenderer)
  .component(
    'JsonFormsMultiStringControlRenderer',
    JsonFormsMultiStringControlRenderer
  )
  .component('JsonFormsStringControlRenderer', JsonFormsStringControlRenderer)
  .component('JsonFormsTagControlRenderer', JsonFormsTagControlRenderer)
  .component('JsonFormsGroupRenderer', JsonFormsGroupRenderer)
  .component(
    'JsonFormsOneOfRadioGroupControlRenderer',
    JsonFormsOneOfRadioGroupControlRenderer
  )
  .component(
    'JsonFormsRadioGroupControlRenderer',
    JsonFormsRadioGroupControlRenderer
  )
  .component('JsonFormsNumberScaleRenderer', JsonFormsNumberScaleRenderer);

Sentry.init({
  app,
  dsn: SENTRY_DSN,
  environment: env,
  enabled: env === 'prod' || env === 'qa', // sentry should only be enabled for prod/qa
  replaysOnErrorSampleRate: 1.0,
  integrations: [
    Sentry.replayIntegration({
      maskAllText: false,
      blockAllMedia: false,
    }),
  ],
});

// Mount vue app
app.mount('#app');
