import { PrettyColumn, ClinicExtra } from '@/types/Api';

import { getIdentifierColumnsFromTemplateString } from '@/utils/patient';

export function getIdentifierColumnsFromColumnsAndTemplate(
  columns: PrettyColumn[],
  template?: string
) {
  let result = columns.filter((c: PrettyColumn) => {
    return c.tags.some((t) => t.name === 'display_identifier');
  });
  if (result.length === 0) {
    if (template) {
      const identifierColumns =
        getIdentifierColumnsFromTemplateString(template);
      result = identifierColumns.map((ic) => {
        const c = columns.find((c) => c.patientAttributesKey === ic);
        if (c === undefined) {
          throw new Error(`Could not find identifier column ${ic}`);
        }
        return c;
      });
    } else {
      result = columns.slice(0, 1);
    }
  }
  return result;
}

export function getIdentifierColumnsFromClinic(clinic: ClinicExtra) {
  return getIdentifierColumnsFromColumnsAndTemplate(
    clinic.piiColumns,
    clinic.piiFormat?.identifier_template
  );
}
