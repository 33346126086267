<template>
  <control-wrapper
    v-bind="controlWrapper"
    :styles="styles"
    :is-focused="isFocused"
    :applied-options="appliedOptions"
  >
    <v-label
      v-if="computedLabel"
      :for="control.id + '-input'"
      v-bind="vuetifyProps('v-label')"
      >{{ computedLabel }}</v-label
    >
    <VItemGroup
      mandatory
      :model-value="control.data"
      @update:model-value="onChange"
    >
      <div
        class="d-flex"
        style="gap: 2px; border-radius: 10px; overflow: hidden"
      >
        <VItem
          v-for="(o, i) in control.options"
          v-slot="{ isSelected, toggle }"
          :key="o.value"
        >
          <VBtn
            :variant="isSelected ? 'elevated' : 'tonal'"
            :style="`padding: 10px; min-width: 0px; flex: 1 1 ${
              1 / control.options.length
            }%;`"
            rounded="0"
            @click="toggle"
          >
            {{ i }}
          </VBtn>
        </VItem>
      </div>
      <div class="d-flex justify-space-between">
        <VLabel>{{ control.options[0].label }}</VLabel>
        <VLabel>{{ control.options[control.options.length - 1].label }}</VLabel>
      </div>
    </VItemGroup>
  </control-wrapper>
</template>

<script lang="ts">
import {
  ControlElement,
  JsonFormsRendererRegistryEntry,
  rankWith,
  isOneOfEnumControl,
  optionIs,
  and,
} from '@jsonforms/core';
import { defineComponent } from 'vue';
import {
  rendererProps,
  useJsonFormsOneOfEnumControl,
  RendererProps,
} from '@jsonforms/vue';
import { default as ControlWrapper } from './JsonFormsControlWrapper.vue';
import { useVuetifyControl } from '@/jsonForms/utils';
import { VRadioGroup, VRadio, VLabel } from 'vuetify/components';

const controlRenderer = defineComponent({
  name: 'NumberScaleControlRenderer',
  components: {
    ControlWrapper,
    VLabel,
  },
  props: {
    ...rendererProps<ControlElement>(),
  },
  setup(props: RendererProps<ControlElement>) {
    const vc = useVuetifyControl(useJsonFormsOneOfEnumControl(props));
    const originalOnChange = vc.onChange;

    return {
      ...vc,
      onChange(value: any) {
        // current onChange is receiving an event object instead of a value
        // we intercept the event object and extract the value and pass it along
        // otherwise it is busted; seems likely to break if jsonforms is
        // updated
        originalOnChange(value);
        // originalOnChange(value?.target?._value);
      },
    };
  },
});

export default controlRenderer;

export const entry: JsonFormsRendererRegistryEntry = {
  renderer: controlRenderer,
  tester: rankWith(20, and(isOneOfEnumControl, optionIs('format', 'scale'))),
};
</script>
