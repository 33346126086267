import { toast } from 'vuetify-sonner';

// vuetify-sonner typing is not correct & does not specify certain option
// params as optional (like ID), and does not export the Options type, so we
// recreate it here so we can cast our options and make typescript happy
import { ToastProps } from 'vuetify-sonner/types';
import { ExternalToast } from 'vue-sonner/lib/types';
type Options = Omit<ToastProps, 'text'> &
  Pick<
    ExternalToast,
    'duration' | 'onAutoClose' | 'onDismiss' | 'id' | 'important'
  >;

export function toastError(message: string, description?: string) {
  toast(message, {
    cardProps: {
      color: 'error',
      class: 'flagler',
    },
    prependIcon: 'tabler-exclamation-circle',
    description,
  } as Options);
}

export function toastSuccess(message: string, description?: string) {
  toast(message, {
    cardProps: {
      color: 'success',
      class: 'flagler',
    },
    cardTextProps: {
      class: 'align-start',
    },
    prependIcon: 'tabler-check',
    description,
  } as Options);
}

export function toastInfo(message: string, description?: string) {
  toast(message, {
    cardProps: {
      color: 'info',
      class: 'flagler',
    },
    cardTextProps: {
      class: 'align-start',
    },
    prependIcon: 'tabler-info-circle',
    description,
  } as Options);
}
