import { defineStore } from 'pinia';
import { unionBy, find, some, orderBy, first } from 'lodash';

import { Chat, Message, Timer, Group, Prompt, User, Batch } from '@/types/Api';
import { startTimer, stopTimer } from '@/flagler-api/timer';
import { getChat } from '@/flagler-api/chat';
import { TimerPriority } from '@/types/Rtm';
import { getColorForTreatment } from '@/utils/rtm';

interface State {
  batches: Batch[];
  chats: Chat[];
  messages: Message[];
  groups: Group[];
  timers: Timer[];
  users: User[];
  phoneChat: Chat | null | undefined;
  prompt: Prompt | undefined;
  timedChatId: string | null | undefined; // associated with active timer
  wantToTimeChatIdByPriority: (string | null)[];
  statsNeedRefresh: boolean;
  assigneeId: string | undefined; // assigneeId of the current view, used to limit when statsNeedRefresh is set
  treatment: string; // TODO: treatment of the current view used to set treatment of Messages, Calls, and TimeLogs
}

export const useRtmStore = defineStore('RtmStore', {
  // persist is property for pinia persist plugin
  // persist: true, // eslint-disable-line
  state: (): State => ({
    batches: [],
    chats: [],
    groups: [],
    messages: [],
    timers: [],
    users: [],
    assigneeId: undefined,
    treatment: 'RTM',
    phoneChat: undefined,
    prompt: undefined,
    timedChatId: undefined,
    statsNeedRefresh: false,
    // an array the length of all of TimerPriority's values
    wantToTimeChatIdByPriority: Array(
      Object.keys(TimerPriority).filter((k) => !isNaN(Number(k))).length
    ).fill(null),
  }),
  getters: {
    treatmentTheme(state): string {
      return getColorForTreatment(state.treatment);
    },
  },
  actions: {
    deleteGroup(group: Group) {
      this.groups = this.groups.filter((g) => g._id !== group._id);
    },
    deleteBatch(batch: Batch) {
      this.batches = this.batches.filter((b) => b._id !== batch._id);
    },
    setPrompt(prompt: Prompt) {
      this.prompt = prompt;
    },
    upsertBatches(batches: Batch[]) {
      this.batches = unionBy(batches, this.batches, '_id');
    },
    upsertChats(chats: Chat[]) {
      // This prevents reloading a chat we already have, that isn't updated (for example when we click on a chat or go
      // back to a folder) from triggering loading the chat stats again
      const relevantChats = this.assigneeId
        ? chats.filter((c) => {
            c.assignee._id === this.assigneeId;
          })
        : chats;
      const hasNewOrUpdatedChats = some(relevantChats, (c) => {
        const existingChat = find(this.chats, { _id: c._id });
        return (
          !existingChat ||
          new Date(c.updatedAt) > new Date(existingChat.updatedAt)
        );
      });
      if (hasNewOrUpdatedChats) {
        this.statsNeedRefresh = true;
      }

      this.chats = unionBy(chats, this.chats, '_id');
    },
    upsertGroups(groups: Group[]) {
      this.groups = unionBy(groups, this.groups, '_id');
    },
    upsertMessages(messages: Message[]) {
      this.messages = unionBy(messages, this.messages, 'tempId');
    },
    upsertTimers(timerOrTimers: Timer[] | Timer) {
      const timers = Array.isArray(timerOrTimers)
        ? timerOrTimers
        : [timerOrTimers];
      this.timers = unionBy(timers, this.timers, 'id');
    },
    upsertUsers(users: User[]) {
      this.users = orderBy(
        unionBy(users, this.users, '_id'),
        ['name'],
        ['asc']
      );
    },
    autoStartAndStopTimers() {
      let newTimedChatId = undefined;
      let i = this.wantToTimeChatIdByPriority.length - 1;

      while (i >= 0 && newTimedChatId === undefined) {
        if (this.wantToTimeChatIdByPriority[i]) {
          newTimedChatId = this.wantToTimeChatIdByPriority[i];
        }
        i = i - 1;
      }

      if (newTimedChatId !== this.timedChatId) {
        if (this.timedChatId) {
          stopTimer(this.timedChatId);
        }
        this.timedChatId = newTimedChatId;
        if (this.timedChatId) {
          startTimer(this.timedChatId);
        }
      }
    },
    stopTimer(chatId: string, priority: TimerPriority) {
      if (this.wantToTimeChatIdByPriority[priority] === chatId) {
        this.wantToTimeChatIdByPriority[priority] = null;
        this.autoStartAndStopTimers();
      } else {
        console.warn(
          'stopTimer called for chatId not in wantToTimeChatIdByPriority'
        );
      }
    },
    startTimer(chatId: string, priority: TimerPriority) {
      this.wantToTimeChatIdByPriority[priority] = chatId;
      this.autoStartAndStopTimers();
    },
    logsForChatId(chatId: string): Timer[] {
      return this.timers
        .filter((t) => t.chat === chatId)
        .sort((a, b) => {
          return a.startedAt > b.startedAt ? -1 : 1;
        });
    },
    async getChatById(chatId: string): Promise<Chat | null> {
      const result = this.chats.find((c) => c._id === chatId);
      return result || (await getChat(chatId));
    },
  },
});
