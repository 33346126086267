import parsePhoneNumber from 'libphonenumber-js';

import { AllRtmChatStatus, RtmChatStatus, CommentFlagKey } from '@/types/Api';

const chatStatusLabels: Record<RtmChatStatus, string> = {
  PENDING: 'Pending',
  ENROLLED: 'Enrolled',
  DECLINED: 'Declined',
};

export function getRtmChatStatusOptions(
  includeAll = false,
  includeDeclined = false
) {
  const statuses = AllRtmChatStatus.filter(
    (s) => s !== 'DECLINED' || includeDeclined
  );
  const items = statuses.map((s) => ({ title: chatStatusLabels[s], value: s }));
  return !includeAll
    ? items
    : [{ title: 'All Statuses', value: null }, ...items];
}

export function formatDuration(milliseconds: number) {
  const millisecondsPerSecond = 1000;
  const millisecondsPerMinute = 60 * millisecondsPerSecond;
  const millisecondsPerHour = 60 * millisecondsPerMinute;

  // Calculate days, hours, minutes, and seconds
  const hours = Math.floor(milliseconds / millisecondsPerHour);
  const minutes = Math.floor(
    (milliseconds % millisecondsPerHour) / millisecondsPerMinute
  );
  const seconds = Math.floor(
    (milliseconds % millisecondsPerMinute) / millisecondsPerSecond
  );

  let result = '';
  if (hours > 0) {
    result += `${hours}:`;
  }
  result += minutes < 10 && hours > 0 ? `0${minutes}:` : `${minutes}:`;
  result += seconds < 10 ? `0${seconds}` : seconds;

  return result.trim();
}

export const chatFlagTitles: Record<CommentFlagKey, string> = {
  unresponsive: 'Unresponsive',
  isLandline: 'Landline',
  callOnly: 'Call Only',
  spanishLanguage: 'Spanish Language',
};

export const formatPhoneNumber = (
  number: string | undefined,
  format = 'NATIONAL'
) => {
  if (!number) return '';
  return (
    parsePhoneNumber(number, { defaultCountry: 'US' })?.format(format) || ''
  );
};

type TreatmentOption = {
  value: string;
  color: string;
};

export const treatmentOptions: TreatmentOption[] = [
  { value: 'RTM', color: `#CD30A4FF` },
  { value: 'CCM', color: '#805ebf' },
  { value: 'SCHEDULE', color: '#4895ef' },
];

type OutreachIntervalOption = {
  title: string;
  value: number;
};

export const outreachIntervalOptions: OutreachIntervalOption[] = [
  { title: 'Daily', value: 24 * 60 * 60 * 1000 },
  { title: 'Every other day', value: 2 * 24 * 60 * 60 * 1000 },
  { title: 'Every 3 days', value: 3 * 24 * 60 * 60 * 1000 },
  { title: 'Once a week', value: 7 * 24 * 60 * 60 * 1000 },
  // { title: 'Every other week', value: 14 * 24 * 60 * 60 * 1000 },
  // { title: 'Once a month', value: 30 * 24 * 60 * 60 * 1000 },
];

export function getColorForTreatment(treatment: string) {
  return (
    treatmentOptions.find((t) => t.value === treatment)?.color || '#CD30A4FF'
  );
}
