import axios from '@axios';

import {
  formatRecommendation,
  getRawColumnFromPrettyColumn,
  getFormattedDateOption,
} from './recommendations';

const performPostToBackend = async (route, postBody) => {
  let requestConfig = {};
  const bearerToken = localStorage.getItem('accessToken') || null;
  if (bearerToken) {
    requestConfig = {
      headers: { Authorization: `Bearer ${bearerToken}` },
    };
  }

  return await axios.post(
    import.meta.env.VITE_BACKEND_API_URL + route,
    postBody,
    requestConfig
  );
};

const performGetToBackend = async (route, params) => {
  const bearerToken = localStorage.getItem('accessToken') || null;
  let requestConfig = {
    params: params,
  };
  if (bearerToken) {
    requestConfig.headers = { Authorization: `Bearer ${bearerToken}` };
  }

  return await axios.get(
    import.meta.env.VITE_BACKEND_API_URL + route,
    requestConfig
  );
};

const performPutToBackend = async (route, putBody) => {
  const bearerToken = localStorage.getItem('accessToken') || null;
  let requestConfig = {};
  if (bearerToken) {
    requestConfig = {
      headers: { Authorization: `Bearer ${bearerToken}` },
    };
  }

  return await axios.put(
    import.meta.env.VITE_BACKEND_API_URL + route,
    putBody,
    requestConfig
  );
};

const performDeleteToBackend = async (route, deleteBody = {}) => {
  let requestConfig = {};
  const bearerToken = localStorage.getItem('accessToken') || null;
  if (bearerToken) {
    requestConfig = {
      headers: { Authorization: `Bearer ${bearerToken}` },
    };
  }

  return await axios.delete(
    import.meta.env.VITE_BACKEND_API_URL + route,
    requestConfig
  );
};

const performPatchToBackend = async (route, patchBody) => {
  let requestConfig = {};
  const bearerToken = localStorage.getItem('accessToken') || null;
  if (bearerToken) {
    requestConfig = {
      headers: { Authorization: `Bearer ${bearerToken}` },
    };
  }

  return await axios.patch(
    import.meta.env.VITE_BACKEND_API_URL + route,
    patchBody,
    requestConfig
  );
};

// For some endpoints with user-generated content, we want to suppress any errors
const performPostToBackendWithSuppressedError = async (route, postBody) => {
  try {
    return await performPostToBackend(route, postBody);
  } catch (e) {
    console.log(e);
  }
};

const refreshToken = async (postBody = {}) => {
  return await performPostToBackend('/auth/refresh', postBody);
};

const getUserProfile = async () => {
  const profile = await performGetToBackend('/user/profile', {});

  return profile;
};

const getFilters = async (clinicId) => {
  return await performGetToBackend('/recommendation/filters', {
    clinicId: clinicId,
  });
};

// based on user; no clinicId
const getClinicStatFilters = () => {
  return performGetToBackend('/clinic/filters');
};

const getRecommendations = async (
  clinicId,
  selectedDate,
  locationFilter,
  sortedColumn,
  sortDirection,
  piiCols,
  includePatient = true,
  includeClinic = true,
  includeRecommendationInfo = true,
  includeRecommendationLifecycle = false,
  limit = -1
) => {
  const postBody = {
    query: {
      clinic: {
        clinicIds: [clinicId],
      },
    },
    includePatient,
    includeClinic,
    includeRecommendationInfo,
    includeRecommendationLifecycle,
    page: 1,
    limit,
  };
  if (selectedDate) {
    postBody.query.startDate = selectedDate;
    postBody.query.endDate = selectedDate;
  }
  if (locationFilter && locationFilter !== []) {
    postBody.query.clinicAttributes = {};
    postBody.query.clinicAttributes.searchTokens = locationFilter;
  }
  if (sortedColumn && sortedColumn !== '') {
    const name = getRawColumnFromPrettyColumn(piiCols, sortedColumn);
    if (name) {
      postBody.sort = [{}];
      postBody.sort[0].name = name;
      postBody.sort[0].target = 'patient';
      if (sortDirection === 'UP') {
        postBody.sort[0].order = 1;
      } else {
        postBody.sort[0].order = -1;
      }
    }
  }
  return await performPostToBackend('/recommendation/search', postBody);
};

const getRecommendationsStatsFreq = async (postBody) => {
  return await performPostToBackend('/recommendation/stats/freq', postBody);
};

const getRecommendationsStatsSummary = async (postBody) => {
  return await performPostToBackend('/recommendation/stats/summary', postBody);
};

const getClinicData = async (clinicId) => {
  // Note use getClinicDataFromStorage when applicable.
  const axiosRes = await performPostToBackend('/clinic/search', {
    query: { clinicIds: [clinicId] },
  });
  if (axiosRes.data.totalCount == 1) {
    // Remove paginated result and replace with simple Clinic object
    axiosRes.data = axiosRes.data.data[0];
  }
  return axiosRes;
};

const getRecommendationChoices = async (clinicId) => {
  return await performGetToBackend('/recommendation/choices/' + clinicId, {});
};

const updateRecommendation = async (
  recommendation_text,
  comment_text,
  recommendation_id,
  clinic_id
) => {
  const putBody = {
    clinicIds: [clinic_id],
    recommendation: recommendation_text,
    comments: {
      text: comment_text,
    },
  };
  return await performPutToBackend(
    '/recommendation/' + recommendation_id,
    putBody
  );
};

const updateRecommendationStatus = async (
  recommendation_id,
  clinic_id,
  status
) => {
  const statusValue = status ? 'ordered' : '';
  const putBody = {
    clinicIds: [clinic_id],
    status: statusValue,
  };
  return await performPutToBackend(
    '/recommendation/' + recommendation_id,
    putBody
  );
};

const getClinicDataFromStorage = () => {
  return JSON.parse(localStorage.getItem('userCompany'))['clinics'];
};

const getFormattedRecommendationsTable = async (
  selectedClinicId,
  selectedDate,
  locationFilter,
  sortedColumn,
  sortDirection
) => {
  try {
    const clinicDataFromStorage = getClinicDataFromStorage();
    const clinicId = selectedClinicId || clinicDataFromStorage[0]._id; // use either selected clinic id, or fall back to first clinic if page hasn't loaded yet.
    const rawFilters = await getFilters(clinicId);
    // @TODO use the clinic store to avoid redundant api call
    const clinicDataFromBackend = await getClinicData(clinicId);
    const recommendationChoices = await getRecommendationChoices(clinicId);

    const recommendations = await getRecommendations(
      clinicId,
      selectedDate,
      locationFilter,
      sortedColumn,
      sortDirection,
      clinicDataFromBackend.data.piiColumns
    );
    const formattedTable = recommendations.data.data.map((r) =>
      formatRecommendation(
        r,
        clinicDataFromBackend.data.piiColumns,
        clinicDataFromBackend.data.procedures.filter(
          (p) => p.type === 'flagler'
        ),
        recommendationChoices.data[0].recommendation
      )
    );

    return formattedTable;
  } catch (e) {
    console.error(e);
    return [];
  }
};

const getFilterDropdownOptions = async (selectedClinicId) => {
  const clinicDataFromStorage = getClinicDataFromStorage();
  const clinicId = selectedClinicId || clinicDataFromStorage[0]._id; // use either selected clinic id, or fall back to first clinic if page hasn't loaded yet.
  const rawFilters = await getFilters(clinicId);

  let dateFilterDropdown = [];
  if (rawFilters.data.lvl2[clinicId]?.dates) {
    // unique date strings only
    dateFilterDropdown = [
      ...new Set(
        rawFilters.data.lvl2[clinicId].dates.map((date) =>
          getFormattedDateOption(date)
        )
      ),
    ];
  }

  let locationFilterDropdown = [];
  if (rawFilters.data.lvl2[clinicId]?.searchTokens) {
    locationFilterDropdown = rawFilters.data.lvl2[clinicId].searchTokens;
  }

  return {
    clinics: clinicDataFromStorage.map((clinic) => {
      return { title: clinic.prettyName || clinic.name, value: clinic._id };
    }),
    dates: dateFilterDropdown,
    locations: locationFilterDropdown,
  };
};

const getTableHeader = async (selectedClinicId) => {
  const clinicDataFromBackend = await getClinicData(selectedClinicId);
  const piiColumns = clinicDataFromBackend.data.piiColumns;

  // Assumption: piiColumns var contains ORDERED list of columns to display in FE with a unique tag per column,
  // even though the piiColumns[*].tags is an array
  const flattenPiiColumns = (arr) =>
    arr.flatMap(({ tags, ...rest }) =>
      tags
        .filter((tag) => {
          return !tag.name.includes('display_');
        })
        .map((tag) => ({
          ...rest,
          ...tag,
        }))
    );
  const piiColumnMap = new Map(); // leverages insertion-order
  flattenPiiColumns(piiColumns).forEach((piiColumnWithTag) => {
    if (piiColumnMap.has(piiColumnWithTag.name)) {
      let existing = piiColumnMap.get(piiColumnWithTag.name);
      existing.size = existing.size + 1;
      return existing;
    } else {
      return piiColumnMap.set(piiColumnWithTag.name, {
        title: piiColumnWithTag.prettyName,
        size: 1,
      });
    }
  });

  return [
    { title: '', size: 1 }, // first column is a placeholder column for spacing purposes
    ...piiColumnMap.values(),
    {
      title: 'Flagler Recommendation',
      size:
        clinicDataFromBackend.data.procedures.filter(
          (p) => p.type === 'flagler'
        ).length + 1,
    },
    { title: 'Clinic Review', size: 4 },
  ];
};

const requestPasswordResetForEmail = async (email) => {
  return await performPostToBackendWithSuppressedError('/user/passwordreset', {
    email,
  });
};

const updatePassword = async (userId, token, password) => {
  return await performPostToBackend('/user/updatepassword', {
    userId,
    token,
    password,
  });
};

const postLogMessage = async (messageType, messageBody = null) => {
  return performPostToBackend('/request/' + messageType, messageBody);
};

const logUserActivity = async (activity, clinicId) => {
  return performPostToBackend('/useractivity/log', { activity, clinicId });
};

const logViewSize = () => {
  let screenWidth =
    window && window.screen && window.screen.width ? window.screen.width : '?';
  let screenHeight =
    window && window.screen && window.screen.height
      ? window.screen.height
      : '?';
  let windowWidth = window && window.innerWidth ? window.innerWidth : '?';
  let windowHeight = window && window.innerHeight ? window.innerHeight : '?';
  let pixelDensity =
    window && window.devicePixelRatio ? window.devicePixelRatio : '?';

  let message = `view-size: ${windowWidth}x${windowHeight} (${screenWidth}x${screenHeight} @${pixelDensity})`;

  logUserActivity(message);
};

export {
  performPostToBackend,
  performGetToBackend,
  performPutToBackend,
  performPatchToBackend,
  performDeleteToBackend,
  refreshToken,
  getUserProfile,
  getFilters,
  getClinicStatFilters,
  getRecommendations,
  getFormattedRecommendationsTable,
  getFilterDropdownOptions,
  updateRecommendation,
  updateRecommendationStatus,
  getTableHeader,
  getRecommendationChoices,
  requestPasswordResetForEmail,
  updatePassword,
  postLogMessage,
  logUserActivity,
  logViewSize,
  getClinicDataFromStorage,
  getClinicData,
};
