import {
  performPostToBackend,
  performPutToBackend,
  performGetToBackend,
  PagedResponse,
} from '@/flagler-api/backend-api';
import { useRtmStore } from '@/stores/RtmStore';
import { useUserStore } from '@/stores/UserStore';
import { User, Role, Permission } from '@/types/Api';

// pageNumber is 1-based
export async function getRtmUsers(
  pageNumber = 1,
  pageSize = 100
): Promise<User[]> {
  const company = JSON.parse(localStorage.getItem('userCompany'));
  const companyId = company._id;
  const response = await performPostToBackend('/user/search', {
    query: { roleNames: ['AdminRTM'], companyIds: [companyId] },
    limit: 100,
  });
  const users = response?.data?.data ?? response?.data ?? [];
  const store = useRtmStore();
  store.upsertUsers(users);
  return users;
}

export async function updateUser(
  userId: string,
  data: Partial<User>
): Promise<User> {
  const response = await performPutToBackend(`/user/${userId}`, data);
  const user = response?.data;
  const storedUser = JSON.parse(localStorage.getItem('userData') ?? '{}');

  if (storedUser._id === userId) {
    // should match what's in LoginStore
    const { userData } = storeToRefs(useUserStore());
    userData.value = {
      ...storedUser,
      avatarUrl: user?.avatarUrl,
      email: user?.email,
      trackRtmTime: user?.trackRtmTime,
      roles: user?.roles.map((role: any) => role.name),
      fullName: user?.name,
    };
  }
  return user;
}

type GetUsersOptions = {
  page?: number;
  limit?: number;
  nameSearch?: string;
  email?: string;
  companyId?: string | string[];
  roleName?: string | string[];
  includeCompany?: boolean;
  activeProvider?: boolean;
};
type GetUsersResponse = PagedResponse & { data: User[] };

export async function getUsers({
  page = 1,
  limit = 10,
  nameSearch,
  email,
  companyId = [],
  roleName = [],
  includeCompany = false,
  activeProvider,
}: GetUsersOptions): Promise<GetUsersResponse | undefined> {
  const provider = {
    IsActive: activeProvider === true ? true : undefined,
  };
  const response = await performPostToBackend('/user/search', {
    query: {
      companyIds: typeof companyId === 'string' ? [companyId] : companyId,
      roleNames: typeof roleName === 'string' ? [roleName] : roleName,
      provider,
      name: nameSearch,
      email,
    },
    page, // page #, 1-based
    limit, // page size
    includeCompany: includeCompany,
  });
  return response?.data;
}

export async function getUser(id: string): Promise<User | undefined> {
  const response = await performPostToBackend('/user/search', {
    query: { _id: id },
    limit: 1,
  });
  return response?.data?.data?.[0];
}

export async function getAllRolesAndPermissions(): Promise<{
  roles: Role[];
  permissions: Permission[];
}> {
  const results = await performGetToBackend('/user/roles/filters');
  return results.data ?? undefined;
}
