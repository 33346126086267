import { defineStore } from 'pinia';
import { LifecyclePanelIdentifier } from '@/types/components/LifecycleDetails';
import { UserPanelIdentifier } from '@/types/components/PageUserId';
import { CompanyPanelIdentifier } from '@/types/components/PageCompanyCompanyId';
import { TabId } from '@/types/components/EngagementTable';
import { PatientFormCategory } from '@/types/Api/PatientForm';
import { useRoute } from 'vue-router';
import { GenericFilter } from '@/types/Ui';
import { SortOn } from '@/types/Ui/Sort';
import { ref, computed, watch } from 'vue';
import router from '@/router';

// using newer setup store syntax so we can watch properties
// https://pinia.vuejs.org/core-concepts/#Setup-Stores
export const usePreferencesStore = defineStore(
  'PreferencesStore',
  () => {
    //////////////////////////////////////////////////////////////////////////////
    // properties that should persist regardless of which account user is using //
    //////////////////////////////////////////////////////////////////////////////

    const lifecyclePanelsOpened = ref<LifecyclePanelIdentifier[]>(['info']);
    const lifecycleDetailsExpanded = ref(false);
    const userPanelsOpened = ref<UserPanelIdentifier[]>(['user']);
    const companyPanelsOpened = ref<CompanyPanelIdentifier[]>(['clinics']);
    const notificationSoundsEnabled = ref(true);
    const stickyFiltersByRouteName = ref<
      Record<string, GenericFilter[] | undefined>
    >({});
    const patientTrackerSearchCurrentTabOnly = ref(false);
    const stickySearchByRouteName = ref<Record<string, string | null>>({});
    const patientTrackingTabId = ref<TabId>('pending-action');
    const healthAssessmentsFormCategory = ref<PatientFormCategory>('bh');
    const healthAssessmentsFormSortOn = ref<SortOn>({
      name: 'stats.mostRecentDate',
      order: -1,
    });
    // track if user account changes
    const userId = ref<string | undefined>();

    ///////////////////////////////////////////////////////////////
    // properties that should be cleared if user account changes //
    ///////////////////////////////////////////////////////////////

    const lastVisitedPath = ref<string | undefined>();

    const chatLastVisitedPath = ref<string | undefined>();

    const preferredClinicId = ref<string | undefined>();

    watch(chatLastVisitedPath, (n) => (lastVisitedPath.value = n));

    // clear certain properties if user account changes
    watch(userId, (n, o) => {
      // only clear if actually setting user account
      if (o !== undefined) {
        lastVisitedPath.value = undefined;
        chatLastVisitedPath.value = undefined;
        preferredClinicId.value = undefined;
      }
    });

    const setStickyFiltersForRouteName = (
      routeName: string,
      filters: GenericFilter[]
    ) => {
      stickyFiltersByRouteName.value = {
        ...stickyFiltersByRouteName.value,
        [routeName]: filters,
      };
    };

    ////////////////////////
    // getters (computed) //
    ////////////////////////

    // redirectToPath is a computed version of lastVisitedPath that confirms that
    // the path is existent before returning it. This is to prevent a user from
    // being unable to do anything if their lastVisitedPath is no longer valid
    // (because of an app change, say)
    const redirectToPath = computed(() => {
      if (lastVisitedPath.value) {
        const route = router.resolve(lastVisitedPath.value);
        // all is a catchall route for fake 404s
        if (route && route.name !== 'all') {
          return lastVisitedPath.value;
        }
      }
      return undefined;
    });

    /////////////////////////////////////////////////////////////////////
    // return properties (refs) getters (computed) actions (functions) //
    /////////////////////////////////////////////////////////////////////

    return {
      lifecyclePanelsOpened,
      lifecycleDetailsExpanded,
      userPanelsOpened,
      companyPanelsOpened,
      userId,
      lastVisitedPath,
      redirectToPath,
      chatLastVisitedPath,
      notificationSoundsEnabled,
      stickyFiltersByRouteName,
      setStickyFiltersForRouteName,
      patientTrackerSearchCurrentTabOnly,
      stickySearchByRouteName,
      preferredClinicId,
      patientTrackingTabId,
      healthAssessmentsFormCategory,
      healthAssessmentsFormSortOn,
    };
  },
  {
    // persist is property for pinia persist plugin
  persist: true, // eslint-disable-line
  }
);
