import { defineStore, skipHydrate } from 'pinia';
import { useBroadcastChannel } from '@/composables/broadcastChannel';

export type LoginMessage = 'login' | 'logout';
export type IdleMessage = 'activity';
export const useBroadcastStore = defineStore('BroadcastStore', () => {
  return {
    login: useBroadcastChannel<LoginMessage>('login'),
    idle: useBroadcastChannel<IdleMessage>('idle'),
  };
});
