const BASE_TITLE = 'Flagler Health Admin';
const SEPARATOR = ' | ';

export function setWindowTitle(...parts) {
  const p = parts.filter((s) => typeof s === 'string' && s.trim().length > 0);
  p.push(BASE_TITLE);
  if (document) {
    document.title = p.join(SEPARATOR);
  }
}
