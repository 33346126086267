import axios from '@axios';
import { LocationQuery } from 'vue-router';

let knownBuildId: string | undefined = undefined;

export function forceRefresh(
  path: string = '',
  query?: LocationQuery,
  flash?: string
) {
  const url = new URL(window.location.origin + path);
  url.searchParams.set('reload-time', Date.now().toString());
  if (flash) {
    url.searchParams.set('flash', flash);
  }
  if (query) {
    for (const p in query) {
      if (typeof query[p] === 'string') {
        url.searchParams.set(p, query[p] as string);
      }
    }
  }
  window.location.href = url.toString();
}

// fetches build-id from the server and compares it with the one we have;
// if they don't match, force a refresh of the page
export async function forceRefreshIfNeeded(path: string, query: LocationQuery) {
  if (window) {
    try {
      const { data: newBuildId } = await axios.get(
        '/build-id?no-cache=' + Date.now().toString()
      );
      if (knownBuildId === undefined) {
        knownBuildId = newBuildId;
      } else if (knownBuildId !== newBuildId) {
        forceRefresh(path, query, 'The app has been updated.');
      }
    } catch (_err) {
      console.log('`npm run build` to generate build-id');
      knownBuildId = '';
    }
  }
}
