// Thanks: https://css-tricks.com/the-trick-to-viewport-units-on-mobile/
import debounce from 'lodash/debounce';

export const useDynamicVhCssProperty = () => {
  const vh = ref(0);

  const updateVh = () => {
    vh.value = window.innerHeight * 0.01;
    setTimeout(
      () => document.documentElement.style.setProperty('--vh', `${vh.value}px`),
      1
    );
  };

  const debouncedUpdateVh = debounce(updateVh, 500);

  tryOnBeforeMount(() => {
    updateVh();
    useEventListener('resize', debouncedUpdateVh);
  });
};
