import { defineStore } from 'pinia';

import { Company, ClinicBasic, CompanyFeatureFlags } from '@/types/Api';
import { Clinic, CompanyWithClinics } from '@/types/Ui';
import {
  getUserCompanies,
  getFeatureFlagsForCompanyId as fetchFeatureFlagsForCompanyId,
} from '@/flagler-api/companies';

import { useClinicStore } from '@/stores/ClinicStore';
import { add } from 'lodash';

export const useCompanyStore = defineStore('CompanyStore', () => {
  const companies = ref<CompanyWithClinics[] | undefined>();
  const featureFlagsByCompanyId = ref<Record<string, CompanyFeatureFlags>>({});

  const startup = () => {
    // this gets companies with full clinic info
    getUserCompanies()
      .then(
        (data: CompanyWithClinics[] | undefined) => (companies.value = data)
      )
      .catch((e) => {
        console.warn('failed to get user companies', e);
      });
  };

  const clear = () => {
    companies.value = undefined;
    featureFlagsByCompanyId.value = {};
  };

  const upsertCompanies = (newCompanies: Company[]) => {
    const clinicStore = useClinicStore();
    const addCompanies: CompanyWithClinics[] = newCompanies.map((c) => {
      return {
        ...c,
        clinics: {
          clinic: c.clinics.map((cId) => {
            return clinicStore.getAdminClinicForId(cId);
          }),
        },
      };
    });
    if (companies.value === undefined) {
      companies.value = addCompanies;
      return;
    }
    let i = addCompanies.length - 1;
    while (i >= 0) {
      const existingIndex = companies.value.findIndex(
        (c) => c._id === addCompanies[i]._id
      );

      if (existingIndex >= 0) {
        companies.value.splice(existingIndex, 1, addCompanies[i]);
        addCompanies.splice(i, 1);
      }
      i = i - 1;
    }
    companies.value = [...companies.value, ...addCompanies];
  };

  const deleteCompany = (companyId: string) => {
    if (companies.value) {
      companies.value = companies.value.filter((c) => c._id !== companyId);
    }
  };

  const patchClinic = (companyId: string, clinic: ClinicBasic) => {
    if (companies.value === undefined) {
      return;
    }
    const companyIndex = companies.value.findIndex((c) => c._id === companyId);
    if (companyIndex >= 0) {
      const company = companies.value[companyIndex];
      const clinicIndex = company.clinics.clinic.findIndex(
        (c: ClinicBasic) => c._id === clinic._id
      );
      if (clinicIndex >= 0) {
        company.clinics.clinic[clinicIndex] = clinic;
      } else {
        company.clinics.clinic.push(clinic);
      }
      companies.value[companyIndex] = company;
    }
  };

  const deleteClinic = (clinicId: string) => {
    if (companies.value) {
      for (let i = 0; i < companies.value.length; i++) {
        const company = companies.value[i];
        if (company.clinics.clinic) {
          company.clinics.clinic = companies.value[i].clinics.clinic.filter(
            (c) => c._id !== clinicId
          );
        }
        companies.value[i] = company;
      }
    }
  };

  const companyForId = (id: string) => {
    if (companies.value) {
      return companies.value.find((c) => c._id === id);
    } else {
      return undefined;
    }
  };

  const companyOptions = computed(() => {
    if (companies.value) {
      return companies.value.map((c) => {
        return {
          title: c.prettyName,
          value: c._id,
        };
      });
    } else {
      return [];
    }
  });

  const getFeatureFlagsForCompanyId = async (companyId: string) => {
    if (!featureFlagsByCompanyId.value[companyId]) {
      const flags = await fetchFeatureFlagsForCompanyId(companyId);
      if (flags) {
        featureFlagsByCompanyId.value[companyId] = flags;
      }
    }
    return featureFlagsByCompanyId.value[companyId];
  };

  const updateFeatureFlagsForCompanyId = (
    companyId: string,
    flags: CompanyFeatureFlags
  ) => {
    featureFlagsByCompanyId.value[companyId] = flags;
  };

  return {
    clear,
    startup,
    companies,
    upsertCompanies,
    deleteCompany,
    patchClinic,
    deleteClinic,
    companyForId,
    companyOptions,
    getFeatureFlagsForCompanyId,
    updateFeatureFlagsForCompanyId,
  };
});
