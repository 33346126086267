<script setup>
import { useThemeConfig } from '@core/composable/useThemeConfig';
import { useAppAbility } from '@/plugins/casl/useAppAbility';
import { useLoginStore } from '@/stores/LoginStore';
import { useBroadcastStore } from '@/stores/BroadcastStore';
import { useGlobalAbility } from '@/composables/globalAbility';
import { onAnyUnauthenticatedPage } from '@/utils/onAnyUnauthenticatedPage';
import { forceRefresh } from '@/utils/forceRefreshIfNeeded';
import { VSonner } from 'vuetify-sonner';

const loginStore = useLoginStore();
const broadcastStore = useBroadcastStore();

const {
  syncInitialLoaderTheme,
  syncVuetifyThemeWithTheme: syncConfigThemeWithVuetifyTheme,
  isAppRtl,
} = useThemeConfig();

// ℹ️ Sync current theme with initial loader theme
syncInitialLoaderTheme();
syncConfigThemeWithVuetifyTheme();

const _router = useRouter();
const _route = useRoute();

const ability = useAppAbility();

onMounted(() => {
  useGlobalAbility(ability);

  loginStore.startup(ability).catch(() => {
    // error if token has expired, does not exist, or if refresh token failed
    if (!onAnyUnauthenticatedPage()) {
      loginStore.logout();
    }
  });
  broadcastStore.login.addListener(({ data }) => {
    if (data === 'logout') {
      loginStore.logout(undefined, false);
    } else {
      forceRefresh(
        '',
        undefined,
        'You have been logged in from another window'
      );
    }
  });
});
</script>

<template>
  <VLocaleProvider :rtl="isAppRtl">
    <VApp>
      <VMain>
        <RouterView />
        <VSonner position="top-right" :visible-toasts="7" :duration="5000" />
      </VMain>
    </VApp>
  </VLocaleProvider>
</template>
