import mock from '@/@fake-db/mock';
const questionnaire = {
  intro:
    'This is introductory text. If it’s not needed we can make it optional. Please answer the following ten questions.',
  outro:
    'Thanks for answering the questions. You have completed the questionnaire. You may go back and correct any answers, or simply close this page.',
  common: [
    'Over the last 2 weeks, how often have you been bothered by any of the following problems?',
  ],
  questions: [
    {
      question: 'Little interest or pleasure in doing things',
      possibleAnswersIndex: 0,
      commonIndex: 0,
    },
    {
      question: 'Feeling down, depressed, or hopeless',
      possibleAnswersIndex: 0,
      commonIndex: 0,
    },
    {
      question: 'Trouble falling or staying asleep, or sleeping too much',
      possibleAnswersIndex: 0,
      commonIndex: 0,
    },
    {
      question: 'Feeling tired or having little energy',
      possibleAnswersIndex: 0,
      commonIndex: 0,
    },
    {
      question: 'Poor appetite or overeating',
      possibleAnswersIndex: 0,
      commonIndex: 0,
    },
    {
      question:
        'Feeling bad about yourself — or that you are a failure or have let yourself or your family down',
      possibleAnswersIndex: 0,
      commonIndex: 0,
    },
    {
      question:
        'Trouble concentrating on things, such as reading the newspaper or watching television',
      possibleAnswersIndex: 0,
      commonIndex: 0,
    },
    {
      question:
        'Moving or speaking so slowly that other people could have noticed. Or the opposite - being so figety or restless that you have been moving around a lot more than usual',
      possibleAnswersIndex: 0,
      commonIndex: 0,
    },
    {
      question:
        'Thoughts that you would be better off dead or of hurting yourself in someway',
      possibleAnswersIndex: 0,
      commonIndex: 0,
    },
    {
      question:
        'If you checked off any problems, how difficult have these problems made it for you to do your work, take care of things at home, or get along with other people?',
      possibleAnswersIndex: 1,
    },
  ],
  possibleAnswers: [
    [
      {
        answer: 'Not at all',
        value: 0,
      },
      {
        answer: 'Several days',
        value: 1,
      },
      {
        answer: 'More than half the days',
        value: 2,
      },
      {
        answer: 'Nearly every day',
        value: 3,
      },
    ],
    [
      {
        answer: 'Not difficult at all',
        value: 0,
      },
      {
        answer: 'Somewhat difficult',
        value: 1,
      },
      {
        answer: 'Very difficult',
        value: 2,
      },
      {
        answer: 'Extremely difficult',
        value: 3,
      },
    ],
  ],
};

function possibleAnswersToSchema(options: { answer: string; value: number }[]) {
  return {
    schema: {
      type: 'object',
      properties: {
        multipleChoice: {
          type: 'number',
          oneOf: options.map((o) => ({ const: o.value, title: o.answer })),
        },
      },
    },
    uiSchema: {
      type: 'Control',
      scope: '#/properties/multipleChoice',
      label: false,
      options: {
        format: 'radio',
      },
    },
  };
}

const answersGiven: (Object | null)[] = new Array(
  questionnaire.questions.length
).fill(null);

mock.onPost('/patient-form').reply((config) => {
  const data = JSON.parse(config.data);
  let lastStepCompleted = -1;
  if (data) {
    const { identifier, previous, answers } = data;
    // our identifier is just an index in our questions
    if (
      answers &&
      identifier >= 0 &&
      identifier < questionnaire.questions.length
    ) {
      answersGiven[identifier] = answers;
    }
    lastStepCompleted = answersGiven.findIndex((a) => a === null);
    if (lastStepCompleted < 0) {
      lastStepCompleted = questionnaire.questions.length;
    }
    let result: Object = {};
    const qIndex =
      identifier !== undefined && identifier !== ''
        ? identifier + (previous ? -1 : 1)
        : -1;

    if (qIndex < 0) {
      result = {
        identifier: -1,
        stepNumber: 0,
        maxStepNumber: questionnaire.questions.length,
        previous: false,
        next: false,
        autoAdvance: false,
        text: questionnaire.intro,
        cta: true,
        ctaLabel: 'Begin',
      };
      return [200, result];
    } else if (qIndex < questionnaire.questions.length) {
      const question = questionnaire.questions[qIndex];
      result = {
        identifier: qIndex,
        ...possibleAnswersToSchema(
          questionnaire.possibleAnswers[
            questionnaire.questions[qIndex].possibleAnswersIndex
          ]
        ),
        stepNumber: qIndex + 1,
        maxStepNumber: questionnaire.questions.length,
        previous: qIndex > 0,
        next:
          (qIndex < questionnaire.questions.length &&
            qIndex < lastStepCompleted - 1) ||
          (qIndex === questionnaire.questions.length - 1 &&
            lastStepCompleted === questionnaire.questions.length),
        autoAdvance: true,
        text: question.question,
        contextText:
          question.commonIndex !== undefined
            ? questionnaire.common[question.commonIndex]
            : undefined,
        answers: answersGiven[qIndex] ?? undefined,
        lastStepCompleted,
      };
      return [200, result];
    } else {
      // assume outro
      result = {
        identifier: questionnaire.questions.length,
        stepNumber: questionnaire.questions.length + 1,
        maxStepNumber: questionnaire.questions.length,
        previous: true,
        next: false,
        autoAdvance: false,
        text: questionnaire.outro,
        cta: false,
        ctaLabel: 'I’m Done',
      };
      return [200, result];
    }
  }
});
